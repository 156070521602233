<div>
  <div class="flex items-center justify-between mb-6">
    <span class="block text-lg font-medium uppercase">
      {{ 'Dashboard.DraftHeader' | translate }}
    </span>
    <ng-container *ngIf="(templatesCount$ | async) != 0 || this.searchDto.name != undefined">
      <app-button
        matTooltipPosition="above"
        [label]="'Dashboard.DraftFilter' | translate"
        [size]="'xs'"
        (onClick)="draftFilter = !draftFilter"
      ></app-button>
    </ng-container>
  </div>
  <!-- filter-->
  <ng-container *ngIf="templates$ | async as templates">
    <div
      class="relative flex items-center gap-4 p-4 border-l-4 border-monza-500"
      [ngClass]="{ 'bg-white': type === 'ORDER', 'bg-gray-100': type === 'FIELD' }"
      *ngIf="draftFilter"
    >
      <app-input
        class="w-full max-w-lg"
        [label]="'Dashboard.SearchDraft' | translate"
        [type]="'text'"
        [isLabelHidden]="true"
        [placeholder]="'Dashboard.DraftSearchPlaceholder' | translate"
        (valueChange)="this.searchDto.name = $event; search()"
      >
      </app-input>
      <!-- <app-checkbox
        label="ARS"
        [checked]="search.productType === 'ARS'"
        (checkedChange)="this.search.productType = ($event?'ARS':undefined);searchOrdersChange()"
      ></app-checkbox>
      <app-checkbox
        label="ARS"
        [checked]="search.productType === 'AVK'"
        (checkedChange)="this.search.productType = ($event?'AVK':undefined);searchOrdersChange()"
      ></app-checkbox> -->
      <div class="w-px h-8 bg-gray-200"></div>
      <ng-container *ngIf="companyService.company$ | async as company">
        <app-button
          *ngFor="let type of company.availableProductTypes"
          [label]="type"
          [type]="searchDto.productType === type ? 'primary' : 'light'"
          (onClick)="searchDto.productType = searchDto.productType === type ? undefined : type; search()"
        >
        </app-button>
      </ng-container>
      <div class="absolute right-2.5 top-2.5">
        <app-button
          [label]="'Dashboard.DraftFilterClose' | translate"
          [size]="'xs'"
          [onlyIcon]="true"
          [icon]="'close'"
          [type]="'ghost'"
          (onClick)="draftFilter = !draftFilter; this.searchDto.name = undefined; search()"
        >
        </app-button>
      </div>
    </div>
    <div *ngIf="draftFilter" class="mb-6 flex">
      <app-badge
        [label]="(templatesCount$ | async) + ' ' + ('General.Results' | translate)"
        [type]="'danger'"
      ></app-badge>
    </div>
    <div class="flex relative">
      <div
        *ngIf="showPrev$ | async"
        class="h-auto mr-4 w-8 flex items-center justify-center cursor-pointer"
        [ngClass]="{ 'bg-white': type === 'ORDER', 'bg-gray-100': type === 'FIELD' }"
        (click)="prev()"
      >
        <app-icon icon="chevron-left"></app-icon>
      </div>
      <div class="relative grid gap-4 w-full" [ngClass]="['grid-cols-' + grid]">
        <ng-container *ngFor="let template of templates">
          <app-draft-box
            (selectTemplate)="create($event); selected.emit()"
            (deleteTemplate)="templateService.delete($event)"
            class="col-span-3 md:col-span-1"
            [template]="template"
          ></app-draft-box>
        </ng-container>
      </div>
      <div
        *ngIf="showNext$ | async"
        class="h-auto ml-4 w-8 flex items-center justify-center cursor-pointer"
        [ngClass]="{ 'bg-white': type === 'ORDER', 'bg-gray-100': type === 'FIELD' }"
        (click)="next()"
      >
        <app-icon icon="chevron-right"></app-icon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(templatesCount$ | async) == 0 && this.searchDto.name == undefined">
    <div class="w-full text-center py-4 text-lg font-semibold">{{ 'Dashboard.NoDrafts' | translate }}</div>
  </ng-container>
</div>
