import { Component, Input } from '@angular/core';
import {
  Order,
  OrderStatusEnum,
  Product,
  ProductLineItem,
  ProductTypeEnum,
  UpdateProductLineItemDto
} from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { OrderService } from '../../../../../services/order.service';
import { BaseEWGSlotComponent } from '../base/base-slot.component';

@Component({
  selector: 'app-ewg-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class EWGLabelComponent extends BaseEWGSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  labelPositionOptions!: Option[];

  fontOptions: Option[] = [
    {
      value: 'Arial',
      description: '',
      meta: '',
      label: 'Arial'
    },
    {
      value: 'Calib',
      description: '',
      meta: '',
      label: 'Calibri'
    },
    {
      value: 'Helv',
      description: '',
      meta: '',
      label: 'Helvetica'
    },
    {
      value: 'NT Rom',
      description: '',
      meta: '',
      label: 'New Times Roman'
    }
  ];

  fontSizeOptions: Option[] = [
    '10',
    '15',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '100'
  ].map((size) => ({
    value: size,
    description: '',
    meta: '',
    label: size
  }));

  colorOptions!: Option[];

  labelActive: boolean = false;
  labelProduct?: ProductLineItem;
  font: Option = this.fontOptions[0];
  fontSize: Option = this.fontSizeOptions[3];
  color!: Option;
  labelPosition!: Option;

  constructor(
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly orderService: OrderService
  ) {
    super(translate);

    this.initLabels();

    this.color = this.colorOptions[0];
    this.labelPosition = this.labelPositionOptions[0];

    this.translate.onLangChange.subscribe((change) => {
      this.initLabels();
      this.updateItem();
    });
  }

  override initItem(): void {}
  override updateItem(firstChange?: boolean | undefined): void {
    this.labelProduct = this.orderService.order$.value?.lineItems?.find(
      (l) => l.addedFrom && l.addedFrom.includes(this.item.id) && l.isLabel
    );

    if (this.labelProduct) {
      this.labelActive = true;
      const font = this.fontOptions.find(
        (opt) => opt.value == this.labelProduct?.additionalDescription?.split(',')?.[0]?.trim()
      );

      if (font) {
        this.font = font;
      }
      const fontSize = this.fontSizeOptions.find(
        (opt) => opt.value == (this.labelProduct?.additionalDescription?.split(',')[1]?.trim()?.split(' ')?.[0] ?? '')
      );
      if (fontSize) {
        this.fontSize = fontSize;
      }

      const desc =
        this.translate.currentLang == 'de'
          ? this.labelProduct?.additionalDescription
          : this.labelProduct?.additionalDescriptionFr;

      const color = this.colorOptions.find((opt) => opt.label == desc?.split(',')[3]?.trim());
      if (color) {
        this.color = color;
      }
      const pos1 = this.labelPositionOptions.find((opt) => opt.label == desc?.split(',')[2]?.trim());
      if (pos1) {
        this.labelPosition = pos1;
      }
    }
  }

  updateLabelActive() {
    if (!this.labelActive) {
      this.updateLabel();
    }
  }

  async updateLabel(label?: string, font?: string, fontSize?: string, position?: string, color?: string) {
    position = this.translate.instant(`Configuration.Label.Pos2.${position}`);
    color = this.translate.instant(`Configuration.Label.Colors.${color}`);

    this.font = this.fontOptions.find((opt) => opt.value == font) ?? this.font;
    this.fontSize = this.fontSizeOptions.find((opt) => opt.value == fontSize) ?? this.fontSize;
    this.color = this.colorOptions.find((opt) => opt.label == color) ?? this.color;
    this.labelPosition = this.labelPositionOptions.find((opt) => opt.label == position) ?? this.labelPosition;

    const updateData: UpdateProductLineItemDto = {
      custom: label,
      additionalDescription: `${font}, ${fontSize} mm, ${position}, ${color}`,
      additionalDescriptionFr: `${font}, ${fontSize} mm, ${position}, ${color}`
    };

    if (!this.labelProduct) {
      if (!label) return;
      const labelProduct = (await this.productService.api.productControllerGetLabelProduct(this.item.type)).data;
      this.orderService.addLineItem({
        productId: labelProduct.id,
        addedFrom: [this.item.id],
        ...updateData
      });
    } else {
      if (!label) {
        this.orderService.removeLineItem(this.labelProduct.id);
        return;
      }

      this.orderService.updateLineItem(this.labelProduct.id, updateData);
    }
  }

  initLabels() {
    this.colorOptions = ['Black', 'White', 'Yellow', 'Red', 'Blue'].map((color) => ({
      value: color,
      description: '',
      meta: '',
      label: this.translate.instant(`Configuration.Label.Colors.${color}`)
    }));
    this.color = this.colorOptions.find((o) => o.value == this.color?.value)!;

    this.labelPositionOptions = ['Left', 'Center', 'Right'].map((pos) => ({
      value: pos,
      description: '',
      meta: '',
      label: this.translate.instant(`Configuration.Label.Pos2.${pos}`)
    }));
    this.labelPosition = this.labelPositionOptions.find((o) => o.value == this.labelPosition?.value)!;
  }
}
