import { Component, Input } from '@angular/core';
import { Order, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { Observable, map } from 'rxjs';
import { BaseEWGSlotComponent } from '../base/base-slot.component';

@Component({
  selector: 'app-ewg-accesories',
  templateUrl: './accesories.component.html',
  styleUrls: ['./accesories.component.scss']
})
export class EwgAccesoriesComponent extends BaseEWGSlotComponent {
  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  roofSlot = 40;
  roofProduct$: Observable<Product> = this.productService
    .getSlot(this.roofSlot, ProductTypeEnum.Ewg)
    .pipe(map((prod) => prod[0]));
  selectedRoof: boolean = false;

  roofCombinations: { [key: number]: number[] } = {
    400: [210],
    600: [210, 300],
    800: [300],
    1000: [300]
  };
  isRoofValid: boolean = true;

  constructor(private readonly translate: TranslateService, private readonly productService: ProductService) {
    super(translate);
  }

  override initItem(): void {}

  override updateItem(firstChange?: boolean | undefined): void {
    // Gets the door products from api
    const addons = this.order.lineItems.filter((l) => l.addedFrom?.includes(this.item.id));

    if (addons.some((a) => a.slot === this.roofSlot)) {
      this.selectedRoof = true;
    } else {
      this.selectedRoof = false;
    }

    if (this.item.width && this.item.depth && this.roofCombinations[this.item.width]?.includes(this.item.depth)) {
      this.isRoofValid = true;
    } else {
      this.isRoofValid = false;
    }
  }

  toggleRoof(id: string) {
    if (this.selectedRoof) {
      this.addAddon.emit({
        slot: this.roofSlot,
        create: { productId: id }
      });
    } else {
      this.removeAddon.emit(this.roofSlot);
    }
  }
}
