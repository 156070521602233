import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Configuration, ConfigurationParameters, Product, ProductApi, ProductTypeEnum } from '../api-client';
import { ToastService } from './toast.service';
import { ASS_SERVICE_CONF } from './conf';
import {
  BehaviorSubject,
  delay,
  firstValueFrom,
  from,
  map,
  observable,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  timeout
} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  api: ProductApi;

  orderID: string | undefined;

  mainProducts$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  readonly MAIN_PRODUCT_LIMIT = 6;
  sortedMainProducts$ = this.mainProducts$.pipe(map((prod) => prod.sort((a, b) => a.sorting - b.sorting))).pipe(
    switchMap((prods) =>
      this.mainProductOffset$.pipe(
        map((offset) => {
          return prods.slice(offset, offset + this.MAIN_PRODUCT_LIMIT);
        })
      )
    )
  );
  mainProductOffset$ = new BehaviorSubject(0);

  allSlotsARS: ReplaySubject<Product[]> = new ReplaySubject();
  allSlotsAVK: ReplaySubject<Product[]> = new ReplaySubject();
  allSlotsAL: ReplaySubject<Product[]> = new ReplaySubject();
  allSlotsSWG: ReplaySubject<Product[]> = new ReplaySubject();
  get allSlots() {
    if (this.currrentType === ProductTypeEnum.Avk) {
      return this.allSlotsAVK;
    } else if (this.currrentType === ProductTypeEnum.Al) {
      return this.allSlotsAL;
    } else if (this.currrentType === ProductTypeEnum.Swg) {
      return this.allSlotsSWG;
    } else {
      return this.allSlotsARS;
    }
  }

  currrentType: undefined | ProductTypeEnum = undefined;

  private _slotsARS: ReplaySubject<Product[]>[] = [];
  private _slotsAVK: ReplaySubject<Product[]>[] = [];
  private _slotsAL: ReplaySubject<Product[]>[] = [];
  private _slotsSWG: ReplaySubject<Product[]>[] = [];
  get _slots() {
    if (this.currrentType === ProductTypeEnum.Avk) {
      return this._slotsAVK;
    } else if (this.currrentType === ProductTypeEnum.Al) {
      return this._slotsAL;
    } else if (this.currrentType === ProductTypeEnum.Swg) {
      return this._slotsSWG;
    } else {
      return this._slotsARS;
    }
  }

  getSlot(slot: number, type: ProductTypeEnum = ProductTypeEnum.Ars) {
    this.currrentType = type;
    const _slots = this._slots;
    if (_slots[slot]) return _slots[slot];
    _slots[slot] = new ReplaySubject();

    this.api
      .productControllerFindAllSetProductsBySlotForProduct(slot.toString(), type)
      .then((d) => _slots[slot].next(d.data));
    return _slots[slot];
  }

  async getSlotAsync(slot: number, type: ProductTypeEnum = ProductTypeEnum.Ars) {
    this.currrentType = type;
    const res = this.getSlot(slot, type);
    return await firstValueFrom(res);
  }

  getByProductnumber(productnumber: string, slot: number, type: ProductTypeEnum = ProductTypeEnum.Ars) {
    this.currrentType = type;
    return this.getSlot(slot, type).pipe(map((res) => res.find((p) => p.productNumber === productnumber)));
  }

  getSlots(slots: string[], type: ProductTypeEnum = ProductTypeEnum.Ars) {
    this.currrentType = type;

    const allSlots = this.allSlots;
    const _slots = this._slots;
    for (const slotKey of Object.keys(_slots)) {
      if (slots.includes(slotKey)) {
        slots.splice(
          slots.findIndex((s) => s == slotKey),
          1
        );
      }
    }
    if (slots.length == 0) return allSlots;

    for (const s of slots) {
      _slots[+s] = new ReplaySubject();
    }

    this.api.productControllerFindAllSetProductsBySlotsForProduct(type, slots).then((data) => {
      allSlots.next(data.data);
      const tempSlotArr: Product[][] = [];
      for (const slotData of data.data) {
        for (const productSlot of slotData.slots) {
          if (tempSlotArr[productSlot.slot] == undefined) {
            tempSlotArr[productSlot.slot] = [];
          }
          tempSlotArr[productSlot.slot].push(slotData);
        }
      }

      for (const [key, value] of Object.entries(tempSlotArr)) {
        _slots[+key].next(value);
      }
    });
    return allSlots;
  }

  constructor(
    public jwtHelper: JwtHelperService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.api = new ProductApi(this.authService.apiConfig, ASS_SERVICE_CONF.BASE_URL);
    this.authService.apiConfig$.subscribe((apiConfig) => {
      this.api = new ProductApi(apiConfig, ASS_SERVICE_CONF.BASE_URL);

      this.api.productControllerFindAllMainProducts().then((res) => {
        this.mainProducts$.next(res.data);
      });
    });

    if (this.authService.apiConfig?.accessToken) {
      this.api.productControllerFindAllMainProducts().then((res) => {
        this.mainProducts$.next(res.data);
      });
    }
  }

  async getDefaultSocket() {
    return (await this.api.productControllerGetDefaultSocket()).data;
  }
}
