import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  Order,
  OrderStatusEnum,
  Product,
  ProductLineItem,
  ProductLineItemSideEnum,
  UpdateProductLineItemDto
} from '../../../../../api-client';
import { Option } from '../../../../../atoms/custom-select/custom-select.component';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AddEWGSlotDTO, UpdateEWGSlotDTO } from '../../ewg.component';

@Component({ template: '' })
export abstract class BaseEWGSlotComponent implements OnChanges, OnInit, OnDestroy {
  abstract item: ProductLineItem;
  abstract order: Order;

  @Output()
  addAddon = new EventEmitter<AddEWGSlotDTO>();

  @Output()
  updateAddon = new EventEmitter<UpdateEWGSlotDTO>();

  @Output()
  removeAddon = new EventEmitter<number>();

  @Output()
  addSlot = new EventEmitter<AddEWGSlotDTO>();

  @Output()
  updateSlot = new EventEmitter<UpdateEWGSlotDTO>();

  @Output()
  removeSlot = new EventEmitter<number>();

  isDoubleDoor = false;
  OrderStatusEnum = OrderStatusEnum;

  langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
    this.initItem();
    this.updateItem();
  });

  constructor(protected readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.initItem();
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      if (this.item && this.item.width) {
        this.isDoubleDoor = this.item.productNumber.includes('dt');
      }
      this.updateItem(changes['item'].firstChange);
    }
  }

  addAddonItem(slot: number, option: Option, update?: UpdateProductLineItemDto) {
    if (option.value == '') {
      this.removeAddon.emit(slot);
    } else {
      this.addAddon.emit({ slot: slot, create: { productId: option.value, ...update } });
    }
  }

  toggleAddonItem(slot: number, productId: string, checked: boolean) {
    if (!checked) {
      this.removeAddon.emit(slot);
    } else {
      this.addAddon.emit({ slot: slot, create: { productId } });
    }
  }

  updateAddonItem(slot: number, updateDto: UpdateProductLineItemDto) {
    this.updateAddon.emit({
      slot,
      update: updateDto
    });
  }

  abstract initItem(): void;

  abstract updateItem(firstChange?: boolean): void;

  productToOptionsPipe(startingElem: string | null = '-', descriptionLabel = true, slotNo?: number) {
    let selected: Option | undefined = undefined;
    return map((s: Product[]) => {
      s.sort((a, b) => a.sorting - b.sorting);
      const res = s.map((slot) => {
        const opt = this.productSlotToOption(slot, descriptionLabel);
        if (
          this.order.lineItems.find((li) => li.slot == slotNo && li.addedFrom?.includes(this.item?.id))?.productId ===
          slot.id
        ) {
          selected = opt;
        }
        return opt;
      });
      if (startingElem != null)
        res.unshift({
          value: '',
          description: '',
          label: startingElem,
          meta: { slot: 0 }
        });
      if (!selected) selected = res[0];
      return { selected, res };
    });
  }

  productSlotToOption(prod: Product, descriptionLabel = true): Option {
    const description =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.description
        : prod.descriptionFr;
    const additionalDescription =
      this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
        ? prod.additionalDescription
        : prod.additionalDescriptionFr;

    let description1 = description.trim().replace(/-$/g, '');
    const description2 = additionalDescription;

    if (
      !description.endsWith(' ') &&
      !description.endsWith('-') &&
      !description.endsWith('- ') &&
      !description2.startsWith(' ')
    ) {
      description1 = description1 + ' ';
    }

    const translated = this.translateService.getStreamOnTranslationChange('none').pipe(
      map((v) => {
        const description =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.description
            : prod.descriptionFr;
        const additionalDescription =
          this.translateService.currentLang === undefined || this.translateService.currentLang !== 'fr'
            ? prod.additionalDescription
            : prod.additionalDescriptionFr;

        let description1 = description.trim().replace(/-$/g, '');
        const description2 = additionalDescription;

        if (
          !description.endsWith(' ') &&
          !description.endsWith('-') &&
          !description.endsWith('- ') &&
          !description2.startsWith(' ')
        ) {
          description1 = description1 + ' ';
        }

        return descriptionLabel ? `${description1}${description2}` : prod.name;
      })
    );

    return {
      value: prod.id,
      description: '',
      label: descriptionLabel ? `${description1}${description2}` : prod.name,
      translated,
      meta: { productNumber: prod.productNumber, slot: prod.slots[0].slot }
    };
  }
}
