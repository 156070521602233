import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { AxiosError, AxiosResponse } from 'axios';
import { BehaviorSubject, filter, first, firstValueFrom, map, ReplaySubject } from 'rxjs';
import {
  CreateFilePayload,
  CreateOrderDto,
  CreateProductLineItemDto,
  Order,
  OrderApi,
  ProductTypeEnum,
  SetAddonProductsDTO,
  UpdateOrderDto,
  UpdateProductLineItemDto
} from '../api-client';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { ASS_SERVICE_CONF } from './conf';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  api: OrderApi;

  orderID: string | undefined;
  order$: BehaviorSubject<Order | undefined> = new BehaviorSubject<Order | undefined>(undefined);

  ProductTypeEnum = ProductTypeEnum;

  clipboard$ = new BehaviorSubject('');

  addedSorting = -1;

  constructor(
    public jwtHelper: JwtHelperService,
    private toastService: ToastService,
    private companyService: CompanyService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.api = new OrderApi(authService.apiConfig, ASS_SERVICE_CONF.BASE_URL);
    this.authService.apiConfig$.subscribe((apiConfig) => {
      this.api = new OrderApi(apiConfig, ASS_SERVICE_CONF.BASE_URL);
    });
  }

  async addPromoToOrder(orderId: string, promoCode: string) {
    return this.api.orderControllerAddPromotion(orderId, promoCode).then((res) => {
      this.order$.next(res.data);
    });
  }

  async removePromotion(orderId: string) {
    return this.api.orderControllerRemovePromotion(orderId).then((res) => {
      this.order$.next(res.data);
    });
  }

  createNewOrder(productID: string) {
    this.companyService.company$
      .pipe(
        filter((c) => c !== null),
        first()
      )
      .subscribe((company) => {
        if (company) {
          let d = new Date();
          const payload: CreateOrderDto = {
            name:
              company.name.toLowerCase().replace(/\s/g, '') +
              '-' +
              d.getDate() +
              (d.getMonth() + 1) +
              d.getHours() +
              d.getMinutes(),
            productId: productID
          };
          this.api
            .orderControllerCreate(payload)
            .then((res) => {
              this.order$.next(res.data);
              this.router.navigate(['/c', res.data.id]);
            })
            .catch(async (error) => {
              console.error(error);
              this.toastService.displayToast({
                type: 'danger',
                message: await firstValueFrom(this.translate.get('API.Order.Error.Create')),
                time: 4000
              });
            });
        }
      });
  }

  loadOrder(id: string) {
    this.orderID = id;
    this.api
      .orderControllerFindOne(this.orderID)
      .then((res) => {
        this.order$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  sortOrder(id: string) {
    this.orderID = id;
    this.api
      .orderControllerSortOrder(this.orderID)
      .then((res) => {
        this.order$.next(res.data);
      })
      .catch(this.catch.bind(this));
  }

  async finishOrder() {
    if (!this.orderID) return;
    return this.api
      .orderControllerFinishOrder(this.orderID)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  async downloadPDF(isOffer = false) {
    if (!this.orderID) return;
    let res: AxiosResponse<any>;

    if (isOffer) {
      res = await this.api.orderControllerGenerateOffer(this.orderID, this.translate.currentLang, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf'
        }
      });
    } else {
      res = await this.api.orderControllerGenerateOverview(this.orderID, this.translate.currentLang, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf'
        }
      });
    }

    const content = res.data as unknown as Blob;
    let exportFilename = `${isOffer ? 'offer' : 'order'}_${this.orderID}.pdf`;
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(content, exportFilename);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(content);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  updateOrder(id: string, payload: UpdateOrderDto) {
    this.api.orderControllerUpdate(id, payload).then(this.thenUpdate.bind(this)).catch(this.catch.bind(this));
  }

  switchCompartments(id: string) {
    this.api.orderControllerAVKSwitchCompartments(id).then(this.thenUpdate.bind(this)).catch(this.catch.bind(this));
  }

  setDeliveryAddress(addressId: string) {
    if (!this.orderID) return;
    this.api
      .orderControllerSetDeliveryAddress(this.orderID, addressId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  setBillingAddress(addressId: string) {
    if (!this.orderID) return;
    this.api
      .orderControllerSetBillingAddress(this.orderID, addressId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addTemplateLineItem(templateId: string, sorting: number) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddTemplate(this.orderID, templateId, sorting.toString())
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  createOrderByTemplate(templateId: string) {
    this.api
      .orderControllerCreateOrderByTemplate(templateId)
      .then((data) => {
        this.thenUpdate(data);
        this.router.navigate(['/c', data.data.id]);
      })
      .catch(this.catch.bind(this));
  }

  removeDeliveryAddress() {
    if (!this.orderID) return;
    this.api
      .orderControllerRemoveDeliveryAddress(this.orderID)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  removeBillingAddress() {
    if (!this.orderID) return;
    this.api
      .orderControllerRemoveBillingAddress(this.orderID)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  async updateLineItemSlot(lineItemId: string, slot: string, payload: UpdateProductLineItemDto) {
    if (!this.orderID) return;
    return this.api
      .orderControllerUpdateLineItemSlot(this.orderID, slot, lineItemId, payload)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  removeLineItemSlot(lineItemId: string, slot: string) {
    if (!this.orderID) return;
    return this.api
      .orderControllerRemoveLineItemSlot(this.orderID, slot, lineItemId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  removeLineItem(lineItemId: string) {
    if (!this.orderID) return;
    this.addedSorting = -1;
    this.api
      .orderControllerRemoveLineItem(this.orderID, lineItemId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  setAddonItems(dto: SetAddonProductsDTO) {
    if (!this.orderID) return;
    this.api
      .orderControllerSetAddonProducts(this.orderID, dto)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addDefaultSocket() {
    if (!this.orderID) return;
    this.api
      .orderControllerAddDefaultSocket(this.orderID)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addDefaultWall() {
    if (!this.orderID) return;
    this.api.orderControllerAddDefaultWall(this.orderID).then(this.thenUpdate.bind(this)).catch(this.catch.bind(this));
  }

  addEWG(sorting: number) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddLineItemByName(this.orderID, 'ewg', sorting)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addSWG(sorting: number) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddLineItemByName(this.orderID, 'swg', sorting)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addDefaultDividerWall(sort: number) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddDefaultDividerWall(this.orderID, sort.toString())
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addLineItem(payload: CreateProductLineItemDto) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddLineItem(this.orderID, payload)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  pasteLineItem(id: string, sorting: string) {
    if (!this.orderID) return;
    this.api
      .orderControllerPasteLineItem(this.orderID, sorting, id)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addLineItemSlot(lineItemId: string, slot: string, payload: CreateProductLineItemDto) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddLineItemSlot(this.orderID, slot, lineItemId, payload)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addAddonProduct(lineItemId: string | undefined, payload: CreateProductLineItemDto) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddAddonProduct(this.orderID, payload, lineItemId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  addAddonProductByName(lineItemId: string | undefined, productNumber: string) {
    if (!this.orderID) return;
    this.api
      .orderControllerAddAddonProductByName(this.orderID, productNumber, lineItemId)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  async removeAddonProduct(lineItemId: string, slot: number) {
    if (!this.orderID) return;
    return this.api
      .orderControllerRemoveAddonProduct(this.orderID, lineItemId, slot)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  updateLineItem(lineItemId: string, payload: UpdateProductLineItemDto) {
    if (!this.orderID) return;
    this.api
      .orderControllerUpdateLineItem(this.orderID, lineItemId, payload)
      .then(this.thenUpdate.bind(this))
      .catch(this.catch.bind(this));
  }

  async addFileToOrder(payload: CreateFilePayload) {
    return new Promise((resolve, reject) => {
      if (!this.orderID) return;
      this.api
        .orderControllerAddFileToOrder(this.orderID, payload)
        .then((data) => {
          this.thenUpdate(data);
          resolve(data);
        })
        .catch((e) => {
          this.catch(e);
          reject(e);
        });
    });
  }

  async removeFileFromOrder() {
    return new Promise((resolve, reject) => {
      if (!this.orderID) return;
      this.api
        .orderControllerRemoveFileFromOrder(this.orderID)
        .then((data) => {
          this.thenUpdate(data);
          resolve(data);
        })
        .catch((e) => {
          this.catch(e);
          reject(e);
        });
    });
  }

  async thenUpdate(res: AxiosResponse<Order, any>) {
    this.order$.next(res.data);
    this.toastService.displayToast({
      type: 'success',
      message: await firstValueFrom(this.translate.get('API.Order.Success')),
      time: 4000
    });
  }

  async catch(error: any) {
    let message = '';
    if ((error as any).response?.data) {
      message = (error as any).response?.data.message;
    } else {
      message = error.message;
    }
    console.error(error);

    const m = await firstValueFrom(this.translate.get(error.response?.data?.message));
    let translatedMessage;
    if (typeof message === 'object') {
      translatedMessage = Object.values(m)[0] as string;
    } else {
      translatedMessage = m;
    }

    this.toastService.displayToast({
      type: 'danger',
      message: translatedMessage,
      time: 4000
    });
  }

  deleteOrder(id: string) {}
}
