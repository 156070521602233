<div class="grid grid-cols-1 lg:grid-cols-2 items-start justify-start col-span-6 gap-y-8 lg:gap-8">
  <div class="flex items-start justify-start gap-4">
    <app-checkbox
      *ngIf="metaProducts$ | async as metaProducts; else  mdLoading"
      class="relative self-end"
      [ngStyle]="{ 'bottom': compartmentProduct?'10px':'0px' }"
      [label]="!compartmentProduct ? ('Configuration.AVK.Compartment.NoCompartment' | translate) : ''"
      [isReadonly]="item.width != undefined && item.width > 2000"
      [checked]="compartmentProduct != undefined"
      (checkedChange)="toggleCompartment(metaProducts)"
    ></app-checkbox>

    <app-custom-select
      *ngIf="compartmentProduct"
      class="w-full"
      [label]="'Configuration.AVK.Front.Side' | translate"
      [options]="sideOptions"
      [selectedValue]="selectedSide"
      (cchange)="changeSide($event)"
    >
    </app-custom-select>

    <app-input
      *ngIf="compartmentProduct"
      [label]="'Configuration.Fields.OpenField.Width' | translate"
      [type]="'number'"
      [unit]="'mm'"
      [value]="compartmentProduct.width"
      (onChange)="updateWidth(+$event)"
    ></app-input>
  </div>
  <div *ngIf="compartmentProduct" class="grid grid-cols-1 lg:grid-cols-2 items-start justify-start h-full gap-4">
    <app-custom-select
      *ngIf="dividerOptions$ | async as dividerOptions; else  mdLoading"
      class="w-full"
      [label]="'Configuration.AVK.Front.Divider' | translate"
      [options]="dividerOptions"
      [selectedValue]="selectedDivider!"
      (cchange)="selectedDivider = $event; setDivider($event.value)"
    >
    </app-custom-select>

    <app-checkbox
      class="relative self-end"
      style="bottom: 10px"
      [label]="'Configuration.AVK.Front.AsDoor' | translate"
      [checked]="hasDoor"
      (checkedChange)="$event ? addDoor() : removeDoor()"
    ></app-checkbox>
  </div>

  <div class="col-span-2 grid gap-8" *ngIf="hasDoor && compartmentProduct != undefined">
    <app-avk-door
      [item]="item"
      [additionalCompartment]="true"
      [show]="hasDoor && compartmentProduct != undefined"
      [doorInstallationArea]="'compartment-right'"
      (addSlot)="addSlot.emit($event)"
      (removeSlot)="removeSlot.emit($event)"
    ></app-avk-door>
  </div>
  <div class="col-span-2" *ngIf="compartmentProduct">
    <app-avk-filter
      [item]="item"
      [slot]="this.currentDoorSlot + 6"
      (addSlot)="addSlot.emit($event)"
      (updateSlot)="updateSlot.emit($event)"
      (removeSlot)="removeSlot.emit($event)"
    ></app-avk-filter>
  </div>
  <div class="col-span-2 grid gap-8" *ngIf="compartmentProduct">
    <app-order-label
      class="col-span-6"
      [subHeader]="true"
      [label]="'Configuration.Fields.OpenField.FrontHorizontalCompartment' | translate"
    ></app-order-label>
    <app-avk-front-horizontal
      [side]="$any(selectedSide.value)"
      [additionalCompartment]="true"
      (addSlot)="addSlot.emit($event)"
      (updateSlot)="updateSlot.emit($event)"
      (removeSlot)="removeSlot.emit($event)"
      [item]="item"
    ></app-avk-front-horizontal>
  </div>
</div>

<ng-template #lgLoading>
  <app-skeleton size="lg"></app-skeleton>
</ng-template>
<ng-template #mdLoading>
  <app-skeleton size="md" class="col-span-3"></app-skeleton>
</ng-template>
<ng-template #xsLoading>
  <app-skeleton size="xs"></app-skeleton>
</ng-template>