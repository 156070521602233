/* tslint:disable */
/* eslint-disable */
/**
 * Simple API
 * Simple API Description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AIJobReturnValue
 */
export interface AIJobReturnValue {
    /**
     * 
     * @type {Order}
     * @memberof AIJobReturnValue
     */
    'order': Order;
    /**
     * 
     * @type {CompletionUsage}
     * @memberof AIJobReturnValue
     */
    'usage': CompletionUsage;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'phone': string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'mainAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'isActive': boolean;
    /**
     * 
     * @type {Company}
     * @memberof Address
     */
    'company': Company;
}
/**
 * 
 * @export
 * @interface AdminUpdateUserDto
 */
export interface AdminUpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateUserDto
     */
    'forcePasswordReset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface AggregateDto
 */
export interface AggregateDto {
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'type'?: AggregateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'timestamp': AggregateDtoTimestampEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'groupBy': AggregateDtoGroupByEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregateDto
     */
    'status': AggregateDtoStatusEnum;
}

export const AggregateDtoTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type AggregateDtoTypeEnum = typeof AggregateDtoTypeEnum[keyof typeof AggregateDtoTypeEnum];
export const AggregateDtoTimestampEnum = {
    Created: 'created',
    Updated: 'updated',
    OfferDate: 'offerDate',
    OrderDate: 'orderDate'
} as const;

export type AggregateDtoTimestampEnum = typeof AggregateDtoTimestampEnum[keyof typeof AggregateDtoTimestampEnum];
export const AggregateDtoGroupByEnum = {
    Daily: 'daily',
    Monthly: 'monthly'
} as const;

export type AggregateDtoGroupByEnum = typeof AggregateDtoGroupByEnum[keyof typeof AggregateDtoGroupByEnum];
export const AggregateDtoStatusEnum = {
    Created: 'Created',
    Ordered: 'Ordered',
    Offer: 'Offer'
} as const;

export type AggregateDtoStatusEnum = typeof AggregateDtoStatusEnum[keyof typeof AggregateDtoStatusEnum];

/**
 * 
 * @export
 * @interface AggregationData
 */
export interface AggregationData {
    /**
     * 
     * @type {number}
     * @memberof AggregationData
     */
    'sum': number;
    /**
     * 
     * @type {number}
     * @memberof AggregationData
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof AggregationData
     */
    'day': AggregationDataDayEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregationData
     */
    'timestamp': string;
}

export const AggregationDataDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AggregationDataDayEnum = typeof AggregationDataDayEnum[keyof typeof AggregationDataDayEnum];

/**
 * 
 * @export
 * @interface AggregationResult
 */
export interface AggregationResult {
    /**
     * 
     * @type {Array<AggregationData>}
     * @memberof AggregationResult
     */
    'data': Array<AggregationData>;
    /**
     * 
     * @type {number}
     * @memberof AggregationResult
     */
    'sum': number;
    /**
     * 
     * @type {number}
     * @memberof AggregationResult
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CSVDownloadDto
 */
export interface CSVDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof CSVDownloadDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CSVDownloadDto
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof CSVDownloadDto
     */
    'type'?: CSVDownloadDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CSVDownloadDto
     */
    'timestamp': CSVDownloadDtoTimestampEnum;
    /**
     * 
     * @type {string}
     * @memberof CSVDownloadDto
     */
    'groupBy': CSVDownloadDtoGroupByEnum;
}

export const CSVDownloadDtoTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type CSVDownloadDtoTypeEnum = typeof CSVDownloadDtoTypeEnum[keyof typeof CSVDownloadDtoTypeEnum];
export const CSVDownloadDtoTimestampEnum = {
    Created: 'created',
    Updated: 'updated',
    OfferDate: 'offerDate',
    OrderDate: 'orderDate'
} as const;

export type CSVDownloadDtoTimestampEnum = typeof CSVDownloadDtoTimestampEnum[keyof typeof CSVDownloadDtoTimestampEnum];
export const CSVDownloadDtoGroupByEnum = {
    Daily: 'daily',
    Monthly: 'monthly'
} as const;

export type CSVDownloadDtoGroupByEnum = typeof CSVDownloadDtoGroupByEnum[keyof typeof CSVDownloadDtoGroupByEnum];

/**
 * 
 * @export
 * @interface CalcResult
 */
export interface CalcResult {
    /**
     * 
     * @type {Order}
     * @memberof CalcResult
     */
    'order': Order;
    /**
     * 
     * @type {object}
     * @memberof CalcResult
     */
    'log': object;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'confirmed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'defaultSlot12': string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'defaultSlot12PerField': boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'defaultSlot13': number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'defaultSlot22': number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'defaultAddonWall': number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'AL_defaultAddonWall': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'AL_defaultSlot12': string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'AL_defaultSlot12PerField': boolean;
    /**
     * 
     * @type {Array<DefaultProduct>}
     * @memberof Company
     */
    'defaultProducts': Array<DefaultProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'deleteRequest': boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'deliveryFee': number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'deliveryFeeThreshold': number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'hasLoyaltyBonus': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    'availableProductTypes': Array<CompanyAvailableProductTypesEnum>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Company
     */
    'addresses': Array<Address>;
    /**
     * 
     * @type {User}
     * @memberof Company
     */
    'admin': User;
    /**
     * 
     * @type {Array<User>}
     * @memberof Company
     */
    'users': Array<User>;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Company
     */
    'discounts': Array<Discount>;
    /**
     * 
     * @type {Array<Template>}
     * @memberof Company
     */
    'templates': Array<Template>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    'invites': Array<string>;
}

export const CompanyAvailableProductTypesEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type CompanyAvailableProductTypesEnum = typeof CompanyAvailableProductTypesEnum[keyof typeof CompanyAvailableProductTypesEnum];

/**
 * 
 * @export
 * @interface CompanyByTokenDTO
 */
export interface CompanyByTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyByTokenDTO
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyByTokenDTO
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CompletionTokensDetails
 */
export interface CompletionTokensDetails {
    /**
     * 
     * @type {number}
     * @memberof CompletionTokensDetails
     */
    'audio_tokens': number;
    /**
     * 
     * @type {number}
     * @memberof CompletionTokensDetails
     */
    'reasoning_tokens': number;
}
/**
 * 
 * @export
 * @interface CompletionUsage
 */
export interface CompletionUsage {
    /**
     * 
     * @type {number}
     * @memberof CompletionUsage
     */
    'completion_tokens': number;
    /**
     * 
     * @type {number}
     * @memberof CompletionUsage
     */
    'prompt_tokens': number;
    /**
     * 
     * @type {number}
     * @memberof CompletionUsage
     */
    'total_tokens': number;
    /**
     * 
     * @type {CompletionTokensDetails}
     * @memberof CompletionUsage
     */
    'completion_tokens_details': CompletionTokensDetails;
    /**
     * 
     * @type {PromptTokensDetails}
     * @memberof CompletionUsage
     */
    'prompt_tokens_details': PromptTokensDetails;
}
/**
 * 
 * @export
 * @interface CreateAddressDto
 */
export interface CreateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'name': string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof CreateCompanyDto
     */
    'address': CreateAddressDto;
}
/**
 * 
 * @export
 * @interface CreateDiscountPayload
 */
export interface CreateDiscountPayload {
    /**
     * Type of the Discount
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'type': CreateDiscountPayloadTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountPayload
     */
    'percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountPayload
     */
    'flat'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'productType'?: CreateDiscountPayloadProductTypeEnum;
    /**
     * 
     * @type {Array<DiscountSeries>}
     * @memberof CreateDiscountPayload
     */
    'series'?: Array<DiscountSeries>;
}

export const CreateDiscountPayloadTypeEnum = {
    Percent: 'Percent',
    Flat: 'Flat',
    Series: 'Series'
} as const;

export type CreateDiscountPayloadTypeEnum = typeof CreateDiscountPayloadTypeEnum[keyof typeof CreateDiscountPayloadTypeEnum];
export const CreateDiscountPayloadProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type CreateDiscountPayloadProductTypeEnum = typeof CreateDiscountPayloadProductTypeEnum[keyof typeof CreateDiscountPayloadProductTypeEnum];

/**
 * 
 * @export
 * @interface CreateFilePayload
 */
export interface CreateFilePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof CreateFilePayload
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'contentType': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFilePayload
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface CreateOrderDto
 */
export interface CreateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface CreateProductDto
 */
export interface CreateProductDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductDto
     */
    'set'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'type': CreateProductDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'isAddon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'isLabel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'slot'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductDto
     */
    'slots'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'basePrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'baseAmount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'sorting': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'abacusId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'internalNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'descriptionFr': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'additionalDescription': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'additionalDescriptionFr': string;
}

export const CreateProductDtoTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type CreateProductDtoTypeEnum = typeof CreateProductDtoTypeEnum[keyof typeof CreateProductDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateProductLineItemDto
 */
export interface CreateProductLineItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProductLineItemDto
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLineItemDto
     */
    'side'?: CreateProductLineItemDtoSideEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLineItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLineItemDto
     */
    'additionalDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'amount2'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'amount3'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductLineItemDto
     */
    'sorting'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLineItemDto
     */
    'custom'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductLineItemDto
     */
    'addedFrom'?: Array<string>;
}

export const CreateProductLineItemDtoSideEnum = {
    Left: 'Left',
    Right: 'Right'
} as const;

export type CreateProductLineItemDtoSideEnum = typeof CreateProductLineItemDtoSideEnum[keyof typeof CreateProductLineItemDtoSideEnum];

/**
 * 
 * @export
 * @interface CreatePromotionPayload
 */
export interface CreatePromotionPayload {
    /**
     * 
     * @type {number}
     * @memberof CreatePromotionPayload
     */
    'percent'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionPayload
     */
    'productType'?: CreatePromotionPayloadProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePromotionPayload
     */
    'maxUsage': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePromotionPayload
     */
    'maxCabins': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePromotionPayload
     */
    'maxPercent': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionPayload
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionPayload
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionPayload
     */
    'name': string;
}

export const CreatePromotionPayloadProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type CreatePromotionPayloadProductTypeEnum = typeof CreatePromotionPayloadProductTypeEnum[keyof typeof CreatePromotionPayloadProductTypeEnum];

/**
 * 
 * @export
 * @interface CreateTemplatePayload
 */
export interface CreateTemplatePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateTemplatePayload
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DefaultProduct
 */
export interface DefaultProduct {
    /**
     * 
     * @type {string}
     * @memberof DefaultProduct
     */
    'productNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DefaultProduct
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'type': DiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'percent': number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'flat': number;
    /**
     * 
     * @type {Array<DiscountSeries>}
     * @memberof Discount
     */
    'series': Array<DiscountSeries>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'productType': DiscountProductTypeEnum;
    /**
     * 
     * @type {Company}
     * @memberof Discount
     */
    'company': Company;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Discount
     */
    'orders': Array<Order>;
}

export const DiscountTypeEnum = {
    Percent: 'Percent',
    Flat: 'Flat',
    Series: 'Series'
} as const;

export type DiscountTypeEnum = typeof DiscountTypeEnum[keyof typeof DiscountTypeEnum];
export const DiscountProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type DiscountProductTypeEnum = typeof DiscountProductTypeEnum[keyof typeof DiscountProductTypeEnum];

/**
 * 
 * @export
 * @interface DiscountSeries
 */
export interface DiscountSeries {
    /**
     * 
     * @type {number}
     * @memberof DiscountSeries
     */
    'minFields': number;
    /**
     * 
     * @type {number}
     * @memberof DiscountSeries
     */
    'discountAmount': number;
}
/**
 * 
 * @export
 * @interface FindAllCompaniesDTO
 */
export interface FindAllCompaniesDTO {
    /**
     * 
     * @type {OrderByCompany}
     * @memberof FindAllCompaniesDTO
     */
    'sort': OrderByCompany;
    /**
     * 
     * @type {SearchCompany}
     * @memberof FindAllCompaniesDTO
     */
    'search': SearchCompany;
}
/**
 * 
 * @export
 * @interface FindAllCompanyOrdersDTO
 */
export interface FindAllCompanyOrdersDTO {
    /**
     * 
     * @type {OrderByOrder}
     * @memberof FindAllCompanyOrdersDTO
     */
    'sort': OrderByOrder;
    /**
     * 
     * @type {SearchOrder}
     * @memberof FindAllCompanyOrdersDTO
     */
    'search': SearchOrder;
}
/**
 * 
 * @export
 * @interface FindAllCompanyOrdersResultDTO
 */
export interface FindAllCompanyOrdersResultDTO {
    /**
     * 
     * @type {Array<Order>}
     * @memberof FindAllCompanyOrdersResultDTO
     */
    'orders': Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyOrdersResultDTO
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAllCompanyUsersDTO
 */
export interface FindAllCompanyUsersDTO {
    /**
     * 
     * @type {OrderByUser}
     * @memberof FindAllCompanyUsersDTO
     */
    'sort': OrderByUser;
    /**
     * 
     * @type {SearchUser}
     * @memberof FindAllCompanyUsersDTO
     */
    'search': SearchUser;
}
/**
 * 
 * @export
 * @interface FindAllCompanyUsersResultDTO
 */
export interface FindAllCompanyUsersResultDTO {
    /**
     * 
     * @type {Array<User>}
     * @memberof FindAllCompanyUsersResultDTO
     */
    'users': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyUsersResultDTO
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAllOrdersDTO
 */
export interface FindAllOrdersDTO {
    /**
     * 
     * @type {OrderByOrderStandalone}
     * @memberof FindAllOrdersDTO
     */
    'sort': OrderByOrderStandalone;
    /**
     * 
     * @type {SearchByOrderStandalone}
     * @memberof FindAllOrdersDTO
     */
    'search': SearchByOrderStandalone;
}
/**
 * 
 * @export
 * @interface FindAllPriceConfigsDTO
 */
export interface FindAllPriceConfigsDTO {
    /**
     * 
     * @type {OrderPriceConfigBy}
     * @memberof FindAllPriceConfigsDTO
     */
    'sort': OrderPriceConfigBy;
    /**
     * 
     * @type {SearchPriceConfigBy}
     * @memberof FindAllPriceConfigsDTO
     */
    'search': SearchPriceConfigBy;
}
/**
 * 
 * @export
 * @interface FindAllPriceConfigsResultDTO
 */
export interface FindAllPriceConfigsResultDTO {
    /**
     * 
     * @type {Array<PriceConfig>}
     * @memberof FindAllPriceConfigsResultDTO
     */
    'priceConfigs': Array<PriceConfig>;
    /**
     * 
     * @type {number}
     * @memberof FindAllPriceConfigsResultDTO
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAllPriceErrorsResultDTO
 */
export interface FindAllPriceErrorsResultDTO {
    /**
     * 
     * @type {Array<PriceError>}
     * @memberof FindAllPriceErrorsResultDTO
     */
    'priceErrors': Array<PriceError>;
    /**
     * 
     * @type {number}
     * @memberof FindAllPriceErrorsResultDTO
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAllUsersDTO
 */
export interface FindAllUsersDTO {
    /**
     * 
     * @type {OrderUserBy}
     * @memberof FindAllUsersDTO
     */
    'sort': OrderUserBy;
    /**
     * 
     * @type {SearchUserUser}
     * @memberof FindAllUsersDTO
     */
    'search': SearchUserUser;
}
/**
 * 
 * @export
 * @interface FindAllUsersResultDTO
 */
export interface FindAllUsersResultDTO {
    /**
     * 
     * @type {Array<User>}
     * @memberof FindAllUsersResultDTO
     */
    'users': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof FindAllUsersResultDTO
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAndCountResultCompany
 */
export interface FindAndCountResultCompany {
    /**
     * 
     * @type {Array<Company>}
     * @memberof FindAndCountResultCompany
     */
    'data': Array<Company>;
    /**
     * 
     * @type {number}
     * @memberof FindAndCountResultCompany
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAndCountResultOrder
 */
export interface FindAndCountResultOrder {
    /**
     * 
     * @type {Array<Order>}
     * @memberof FindAndCountResultOrder
     */
    'data': Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof FindAndCountResultOrder
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAndCountResultPromotion
 */
export interface FindAndCountResultPromotion {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof FindAndCountResultPromotion
     */
    'data': Array<Promotion>;
    /**
     * 
     * @type {number}
     * @memberof FindAndCountResultPromotion
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FindAndCountResultTemplate
 */
export interface FindAndCountResultTemplate {
    /**
     * 
     * @type {Array<Template>}
     * @memberof FindAndCountResultTemplate
     */
    'data': Array<Template>;
    /**
     * 
     * @type {number}
     * @memberof FindAndCountResultTemplate
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ForgotPassswordPayload
 */
export interface ForgotPassswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassswordPayload
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InviteCompanyDto
 */
export interface InviteCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof InviteCompanyDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface JobLogResult
 */
export interface JobLogResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof JobLogResult
     */
    'logs': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof JobLogResult
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface JobResult
 */
export interface JobResult {
    /**
     * 
     * @type {string}
     * @memberof JobResult
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof JobResult
     */
    'status': JobResultStatusEnum;
    /**
     * 
     * @type {AIJobReturnValue}
     * @memberof JobResult
     */
    'returnvalue': AIJobReturnValue;
}

export const JobResultStatusEnum = {
    Completed: 'completed',
    Waiting: 'waiting',
    Active: 'active',
    Delayed: 'delayed',
    Failed: 'failed',
    Paused: 'paused'
} as const;

export type JobResultStatusEnum = typeof JobResultStatusEnum[keyof typeof JobResultStatusEnum];

/**
 * 
 * @export
 * @interface JobResults
 */
export interface JobResults {
    /**
     * 
     * @type {Array<JobResult>}
     * @memberof JobResults
     */
    'jobResults': Array<JobResult>;
    /**
     * 
     * @type {number}
     * @memberof JobResults
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface LoginPayload
 */
export interface LoginPayload {
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'expiresIn': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
    /**
     * 
     * @type {User}
     * @memberof LoginResponse
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface LookupDTO
 */
export interface LookupDTO {
    /**
     * 
     * @type {string}
     * @memberof LookupDTO
     */
    'type': LookupDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LookupDTO
     */
    'color': LookupDTOColorEnum;
    /**
     * 
     * @type {number}
     * @memberof LookupDTO
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof LookupDTO
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof LookupDTO
     */
    'depth': number;
    /**
     * 
     * @type {string}
     * @memberof LookupDTO
     */
    'customSelector': string;
}

export const LookupDTOTypeEnum = {
    Cabin: 'Cabin',
    Socket: 'Socket',
    SideWall: 'SideWall',
    DividerWall: 'DividerWall',
    BackWall: 'BackWall',
    BackWallPaint: 'BackWallPaint',
    AssemblyPlate: 'AssemblyPlate',
    RubberSeal: 'RubberSeal',
    Klapptablar: 'Klapptablar',
    Ceiling: 'Ceiling',
    Door: 'Door',
    Floor: 'Floor',
    Ip54: 'IP54',
    Custom: 'Custom',
    FrontV: 'FrontV',
    FrontH: 'FrontH',
    FrontDoor: 'FrontDoor',
    DoubleDoor: 'DoubleDoor',
    Elox: 'Elox',
    Tablar: 'Tablar'
} as const;

export type LookupDTOTypeEnum = typeof LookupDTOTypeEnum[keyof typeof LookupDTOTypeEnum];
export const LookupDTOColorEnum = {
    Color: 'Ral-Color',
    _7035: 'Ral-7035'
} as const;

export type LookupDTOColorEnum = typeof LookupDTOColorEnum[keyof typeof LookupDTOColorEnum];

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'key': string;
    /**
     * 
     * @type {Order}
     * @memberof ModelFile
     */
    'order': Order;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'productType': OrderProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'depth': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'priceSum': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'nettoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'deliveryFee': number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'status': OrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'offerDate': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'deliveryDate': string;
    /**
     * 
     * @type {Array<ProductLineItem>}
     * @memberof Order
     */
    'lineItems': Array<ProductLineItem>;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    'user': User;
    /**
     * 
     * @type {Discount}
     * @memberof Order
     */
    'activeDiscount': Discount;
    /**
     * 
     * @type {Promotion}
     * @memberof Order
     */
    'activePromotion': Promotion;
    /**
     * 
     * @type {any}
     * @memberof Order
     */
    'file': any;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'deliveryAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'billingAddress': Address;
}

export const OrderProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type OrderProductTypeEnum = typeof OrderProductTypeEnum[keyof typeof OrderProductTypeEnum];
export const OrderStatusEnum = {
    Created: 'Created',
    Ordered: 'Ordered',
    Offer: 'Offer'
} as const;

export type OrderStatusEnum = typeof OrderStatusEnum[keyof typeof OrderStatusEnum];

/**
 * 
 * @export
 * @interface OrderByCompany
 */
export interface OrderByCompany {
    /**
     * 
     * @type {OrderCompanyByAdmin}
     * @memberof OrderByCompany
     */
    'admin'?: OrderCompanyByAdmin;
    /**
     * 
     * @type {string}
     * @memberof OrderByCompany
     */
    'name'?: OrderByCompanyNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByCompany
     */
    'created'?: OrderByCompanyCreatedEnum;
}

export const OrderByCompanyNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByCompanyNameEnum = typeof OrderByCompanyNameEnum[keyof typeof OrderByCompanyNameEnum];
export const OrderByCompanyCreatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByCompanyCreatedEnum = typeof OrderByCompanyCreatedEnum[keyof typeof OrderByCompanyCreatedEnum];

/**
 * 
 * @export
 * @interface OrderByOrder
 */
export interface OrderByOrder {
    /**
     * 
     * @type {OrderByOrderUser}
     * @memberof OrderByOrder
     */
    'user'?: OrderByOrderUser;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrder
     */
    'priceSum'?: OrderByOrderPriceSumEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrder
     */
    'name'?: OrderByOrderNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrder
     */
    'created'?: OrderByOrderCreatedEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrder
     */
    'updated'?: OrderByOrderUpdatedEnum;
}

export const OrderByOrderPriceSumEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderPriceSumEnum = typeof OrderByOrderPriceSumEnum[keyof typeof OrderByOrderPriceSumEnum];
export const OrderByOrderNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderNameEnum = typeof OrderByOrderNameEnum[keyof typeof OrderByOrderNameEnum];
export const OrderByOrderCreatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderCreatedEnum = typeof OrderByOrderCreatedEnum[keyof typeof OrderByOrderCreatedEnum];
export const OrderByOrderUpdatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderUpdatedEnum = typeof OrderByOrderUpdatedEnum[keyof typeof OrderByOrderUpdatedEnum];

/**
 * 
 * @export
 * @interface OrderByOrderCompany
 */
export interface OrderByOrderCompany {
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderCompany
     */
    'name'?: OrderByOrderCompanyNameEnum;
}

export const OrderByOrderCompanyNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderCompanyNameEnum = typeof OrderByOrderCompanyNameEnum[keyof typeof OrderByOrderCompanyNameEnum];

/**
 * 
 * @export
 * @interface OrderByOrderStandalone
 */
export interface OrderByOrderStandalone {
    /**
     * 
     * @type {OrderByUserStandalone}
     * @memberof OrderByOrderStandalone
     */
    'user'?: OrderByUserStandalone;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderStandalone
     */
    'name'?: OrderByOrderStandaloneNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderStandalone
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderStandalone
     */
    'nettoPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderStandalone
     */
    'created'?: OrderByOrderStandaloneCreatedEnum;
}

export const OrderByOrderStandaloneNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderStandaloneNameEnum = typeof OrderByOrderStandaloneNameEnum[keyof typeof OrderByOrderStandaloneNameEnum];
export const OrderByOrderStandaloneCreatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderStandaloneCreatedEnum = typeof OrderByOrderStandaloneCreatedEnum[keyof typeof OrderByOrderStandaloneCreatedEnum];

/**
 * 
 * @export
 * @interface OrderByOrderStandaloneByUserCompany
 */
export interface OrderByOrderStandaloneByUserCompany {
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderStandaloneByUserCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface OrderByOrderUser
 */
export interface OrderByOrderUser {
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderUser
     */
    'firstName'?: OrderByOrderUserFirstNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByOrderUser
     */
    'lastName'?: OrderByOrderUserLastNameEnum;
}

export const OrderByOrderUserFirstNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderUserFirstNameEnum = typeof OrderByOrderUserFirstNameEnum[keyof typeof OrderByOrderUserFirstNameEnum];
export const OrderByOrderUserLastNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByOrderUserLastNameEnum = typeof OrderByOrderUserLastNameEnum[keyof typeof OrderByOrderUserLastNameEnum];

/**
 * 
 * @export
 * @interface OrderByUser
 */
export interface OrderByUser {
    /**
     * 
     * @type {string}
     * @memberof OrderByUser
     */
    'firstName'?: OrderByUserFirstNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByUser
     */
    'lastName'?: OrderByUserLastNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByUser
     */
    'email'?: OrderByUserEmailEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByUser
     */
    'created'?: OrderByUserCreatedEnum;
}

export const OrderByUserFirstNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserFirstNameEnum = typeof OrderByUserFirstNameEnum[keyof typeof OrderByUserFirstNameEnum];
export const OrderByUserLastNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserLastNameEnum = typeof OrderByUserLastNameEnum[keyof typeof OrderByUserLastNameEnum];
export const OrderByUserEmailEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserEmailEnum = typeof OrderByUserEmailEnum[keyof typeof OrderByUserEmailEnum];
export const OrderByUserCreatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserCreatedEnum = typeof OrderByUserCreatedEnum[keyof typeof OrderByUserCreatedEnum];

/**
 * 
 * @export
 * @interface OrderByUserStandalone
 */
export interface OrderByUserStandalone {
    /**
     * 
     * @type {string}
     * @memberof OrderByUserStandalone
     */
    'firstName'?: OrderByUserStandaloneFirstNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderByUserStandalone
     */
    'lastName'?: OrderByUserStandaloneLastNameEnum;
    /**
     * 
     * @type {OrderByOrderStandaloneByUserCompany}
     * @memberof OrderByUserStandalone
     */
    'company'?: OrderByOrderStandaloneByUserCompany;
}

export const OrderByUserStandaloneFirstNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserStandaloneFirstNameEnum = typeof OrderByUserStandaloneFirstNameEnum[keyof typeof OrderByUserStandaloneFirstNameEnum];
export const OrderByUserStandaloneLastNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderByUserStandaloneLastNameEnum = typeof OrderByUserStandaloneLastNameEnum[keyof typeof OrderByUserStandaloneLastNameEnum];

/**
 * 
 * @export
 * @interface OrderCompanyByAdmin
 */
export interface OrderCompanyByAdmin {
    /**
     * 
     * @type {string}
     * @memberof OrderCompanyByAdmin
     */
    'firstName'?: OrderCompanyByAdminFirstNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderCompanyByAdmin
     */
    'lastName'?: OrderCompanyByAdminLastNameEnum;
}

export const OrderCompanyByAdminFirstNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderCompanyByAdminFirstNameEnum = typeof OrderCompanyByAdminFirstNameEnum[keyof typeof OrderCompanyByAdminFirstNameEnum];
export const OrderCompanyByAdminLastNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderCompanyByAdminLastNameEnum = typeof OrderCompanyByAdminLastNameEnum[keyof typeof OrderCompanyByAdminLastNameEnum];

/**
 * 
 * @export
 * @interface OrderPriceConfigBy
 */
export interface OrderPriceConfigBy {
    /**
     * 
     * @type {string}
     * @memberof OrderPriceConfigBy
     */
    'width'?: OrderPriceConfigByWidthEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPriceConfigBy
     */
    'height'?: OrderPriceConfigByHeightEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPriceConfigBy
     */
    'depth'?: OrderPriceConfigByDepthEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPriceConfigBy
     */
    'price'?: OrderPriceConfigByPriceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPriceConfigBy
     */
    'type'?: OrderPriceConfigByTypeEnum;
}

export const OrderPriceConfigByWidthEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderPriceConfigByWidthEnum = typeof OrderPriceConfigByWidthEnum[keyof typeof OrderPriceConfigByWidthEnum];
export const OrderPriceConfigByHeightEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderPriceConfigByHeightEnum = typeof OrderPriceConfigByHeightEnum[keyof typeof OrderPriceConfigByHeightEnum];
export const OrderPriceConfigByDepthEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderPriceConfigByDepthEnum = typeof OrderPriceConfigByDepthEnum[keyof typeof OrderPriceConfigByDepthEnum];
export const OrderPriceConfigByPriceEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderPriceConfigByPriceEnum = typeof OrderPriceConfigByPriceEnum[keyof typeof OrderPriceConfigByPriceEnum];
export const OrderPriceConfigByTypeEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderPriceConfigByTypeEnum = typeof OrderPriceConfigByTypeEnum[keyof typeof OrderPriceConfigByTypeEnum];

/**
 * 
 * @export
 * @interface OrderUserBy
 */
export interface OrderUserBy {
    /**
     * 
     * @type {OrderByOrderCompany}
     * @memberof OrderUserBy
     */
    'company'?: OrderByOrderCompany;
    /**
     * 
     * @type {string}
     * @memberof OrderUserBy
     */
    'firstName'?: OrderUserByFirstNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderUserBy
     */
    'lastName'?: OrderUserByLastNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderUserBy
     */
    'email'?: OrderUserByEmailEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderUserBy
     */
    'created'?: OrderUserByCreatedEnum;
}

export const OrderUserByFirstNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderUserByFirstNameEnum = typeof OrderUserByFirstNameEnum[keyof typeof OrderUserByFirstNameEnum];
export const OrderUserByLastNameEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderUserByLastNameEnum = typeof OrderUserByLastNameEnum[keyof typeof OrderUserByLastNameEnum];
export const OrderUserByEmailEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderUserByEmailEnum = typeof OrderUserByEmailEnum[keyof typeof OrderUserByEmailEnum];
export const OrderUserByCreatedEnum = {
    _0: '0',
    _1: '1',
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderUserByCreatedEnum = typeof OrderUserByCreatedEnum[keyof typeof OrderUserByCreatedEnum];

/**
 * 
 * @export
 * @interface PriceConfig
 */
export interface PriceConfig {
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceConfig
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConfig
     */
    'productType': PriceConfigProductTypeEnum;
}

export const PriceConfigProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type PriceConfigProductTypeEnum = typeof PriceConfigProductTypeEnum[keyof typeof PriceConfigProductTypeEnum];

/**
 * 
 * @export
 * @interface PriceError
 */
export interface PriceError {
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceError
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof PriceError
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'slot'?: number;
    /**
     * 
     * @type {Array<ProductSlot>}
     * @memberof Product
     */
    'slots': Array<ProductSlot>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'type': ProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'sorting': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productNumber': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'internalNumber': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isWarehouseCabinet': boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'descriptionFr': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'additionalDescription': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'additionalDescriptionFr': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isAddon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isLabel': boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'basePrice': number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'baseAmount': number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'set': Array<Product>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'inSet': Array<Product>;
}

export const ProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type ProductTypeEnum = typeof ProductTypeEnum[keyof typeof ProductTypeEnum];

/**
 * 
 * @export
 * @interface ProductLineItem
 */
export interface ProductLineItem {
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'type': ProductLineItemTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'sorting': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isWarehouseCabinet': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isLoyaltyApplicable': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'slot': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'amount2': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'amount3': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'nameFr': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'ipProtectionType': string;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'descriptionFr': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'additionalDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'additionalDescriptionFr': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'custom'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isField': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isWall': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isDividerWall': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isAddon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isSocket': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'isLabel': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'depth'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLineItem
     */
    'override': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLineItem
     */
    'addedFrom': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductLineItem
     */
    'group': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLineItem
     */
    'side'?: ProductLineItemSideEnum;
    /**
     * 
     * @type {Array<ProductLineItem>}
     * @memberof ProductLineItem
     */
    'set': Array<ProductLineItem>;
    /**
     * 
     * @type {ProductLineItem}
     * @memberof ProductLineItem
     */
    'parent': ProductLineItem;
    /**
     * 
     * @type {Order}
     * @memberof ProductLineItem
     */
    'order': Order;
}

export const ProductLineItemTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type ProductLineItemTypeEnum = typeof ProductLineItemTypeEnum[keyof typeof ProductLineItemTypeEnum];
export const ProductLineItemSideEnum = {
    Left: 'Left',
    Right: 'Right'
} as const;

export type ProductLineItemSideEnum = typeof ProductLineItemSideEnum[keyof typeof ProductLineItemSideEnum];

/**
 * 
 * @export
 * @interface ProductSlot
 */
export interface ProductSlot {
    /**
     * 
     * @type {number}
     * @memberof ProductSlot
     */
    'slot': number;
    /**
     * 
     * @type {Product}
     * @memberof ProductSlot
     */
    'product': Product;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'maxUsage': number;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'maxCabins': number;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'maxPercent': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'percent': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'productType': PromotionProductTypeEnum;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Promotion
     */
    'orders': Array<Order>;
}

export const PromotionProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type PromotionProductTypeEnum = typeof PromotionProductTypeEnum[keyof typeof PromotionProductTypeEnum];

/**
 * 
 * @export
 * @interface PromptTokensDetails
 */
export interface PromptTokensDetails {
    /**
     * 
     * @type {number}
     * @memberof PromptTokensDetails
     */
    'audio_tokens': number;
    /**
     * 
     * @type {number}
     * @memberof PromptTokensDetails
     */
    'cached_tokens': number;
}
/**
 * 
 * @export
 * @interface RegisterPayload
 */
export interface RegisterPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'passwordConfirmation': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'companyName': string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof RegisterPayload
     */
    'companyAddress': CreateAddressDto;
}
/**
 * 
 * @export
 * @interface RegisterWithTokenPayload
 */
export interface RegisterWithTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterWithTokenPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithTokenPayload
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithTokenPayload
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithTokenPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterWithTokenPayload
     */
    'passwordConfirmation': string;
}
/**
 * 
 * @export
 * @interface ResetPassswordPayload
 */
export interface ResetPassswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'passwordConfirmation': string;
}
/**
 * 
 * @export
 * @interface SearchByOrderStandalone
 */
export interface SearchByOrderStandalone {
    /**
     * 
     * @type {SearchByOrderStandaloneByUser}
     * @memberof SearchByOrderStandalone
     */
    'user'?: SearchByOrderStandaloneByUser;
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandalone
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandalone
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandalone
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandalone
     */
    'productType'?: SearchByOrderStandaloneProductTypeEnum;
}

export const SearchByOrderStandaloneProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type SearchByOrderStandaloneProductTypeEnum = typeof SearchByOrderStandaloneProductTypeEnum[keyof typeof SearchByOrderStandaloneProductTypeEnum];

/**
 * 
 * @export
 * @interface SearchByOrderStandaloneByUser
 */
export interface SearchByOrderStandaloneByUser {
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandaloneByUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandaloneByUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {SearchByOrderStandaloneByUserCompany}
     * @memberof SearchByOrderStandaloneByUser
     */
    'company'?: SearchByOrderStandaloneByUserCompany;
}
/**
 * 
 * @export
 * @interface SearchByOrderStandaloneByUserCompany
 */
export interface SearchByOrderStandaloneByUserCompany {
    /**
     * 
     * @type {string}
     * @memberof SearchByOrderStandaloneByUserCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SearchCompany
 */
export interface SearchCompany {
    /**
     * 
     * @type {SearchCompanyByAdmin}
     * @memberof SearchCompany
     */
    'admin'?: SearchCompanyByAdmin;
    /**
     * 
     * @type {string}
     * @memberof SearchCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SearchCompanyByAdmin
 */
export interface SearchCompanyByAdmin {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyByAdmin
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyByAdmin
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface SearchOrder
 */
export interface SearchOrder {
    /**
     * 
     * @type {SearchOrderUser}
     * @memberof SearchOrder
     */
    'user'?: SearchOrderUser;
    /**
     * 
     * @type {string}
     * @memberof SearchOrder
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrder
     */
    'onlyOrdered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchOrder
     */
    'productType'?: SearchOrderProductTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SearchOrder
     */
    'onlyMe'?: boolean;
}

export const SearchOrderProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type SearchOrderProductTypeEnum = typeof SearchOrderProductTypeEnum[keyof typeof SearchOrderProductTypeEnum];

/**
 * 
 * @export
 * @interface SearchOrderUser
 */
export interface SearchOrderUser {
    /**
     * 
     * @type {string}
     * @memberof SearchOrderUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderUser
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface SearchPriceConfigBy
 */
export interface SearchPriceConfigBy {
    /**
     * 
     * @type {string}
     * @memberof SearchPriceConfigBy
     */
    'type'?: SearchPriceConfigByTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchPriceConfigBy
     */
    'customSelector'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPriceConfigBy
     */
    'productType'?: SearchPriceConfigByProductTypeEnum;
}

export const SearchPriceConfigByTypeEnum = {
    Cabin: 'Cabin',
    Socket: 'Socket',
    SideWall: 'SideWall',
    DividerWall: 'DividerWall',
    BackWall: 'BackWall',
    BackWallPaint: 'BackWallPaint',
    AssemblyPlate: 'AssemblyPlate',
    RubberSeal: 'RubberSeal',
    Klapptablar: 'Klapptablar',
    Ceiling: 'Ceiling',
    Door: 'Door',
    Floor: 'Floor',
    Ip54: 'IP54',
    Custom: 'Custom',
    FrontV: 'FrontV',
    FrontH: 'FrontH',
    FrontDoor: 'FrontDoor',
    DoubleDoor: 'DoubleDoor',
    Elox: 'Elox',
    Tablar: 'Tablar'
} as const;

export type SearchPriceConfigByTypeEnum = typeof SearchPriceConfigByTypeEnum[keyof typeof SearchPriceConfigByTypeEnum];
export const SearchPriceConfigByProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type SearchPriceConfigByProductTypeEnum = typeof SearchPriceConfigByProductTypeEnum[keyof typeof SearchPriceConfigByProductTypeEnum];

/**
 * 
 * @export
 * @interface SearchTemplatePayload
 */
export interface SearchTemplatePayload {
    /**
     * 
     * @type {string}
     * @memberof SearchTemplatePayload
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTemplatePayload
     */
    'productType'?: SearchTemplatePayloadProductTypeEnum;
}

export const SearchTemplatePayloadProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type SearchTemplatePayloadProductTypeEnum = typeof SearchTemplatePayloadProductTypeEnum[keyof typeof SearchTemplatePayloadProductTypeEnum];

/**
 * 
 * @export
 * @interface SearchUser
 */
export interface SearchUser {
    /**
     * 
     * @type {string}
     * @memberof SearchUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SearchUserCompany
 */
export interface SearchUserCompany {
    /**
     * 
     * @type {string}
     * @memberof SearchUserCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SearchUserUser
 */
export interface SearchUserUser {
    /**
     * 
     * @type {string}
     * @memberof SearchUserUser
     */
    'id'?: string;
    /**
     * 
     * @type {SearchUserCompany}
     * @memberof SearchUserUser
     */
    'company'?: SearchUserCompany;
    /**
     * 
     * @type {string}
     * @memberof SearchUserUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SetAddonProductDTO
 */
export interface SetAddonProductDTO {
    /**
     * 
     * @type {string}
     * @memberof SetAddonProductDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof SetAddonProductDTO
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface SetAddonProductsDTO
 */
export interface SetAddonProductsDTO {
    /**
     * 
     * @type {Array<SetAddonProductDTO>}
     * @memberof SetAddonProductsDTO
     */
    'addons': Array<SetAddonProductDTO>;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'productType': TemplateProductTypeEnum;
    /**
     * 
     * @type {Order}
     * @memberof Template
     */
    'serializedOrder': Order;
    /**
     * 
     * @type {ProductLineItem}
     * @memberof Template
     */
    'serializedLineItem': ProductLineItem;
    /**
     * 
     * @type {Array<Template>}
     * @memberof Template
     */
    'templateItems': Array<Template>;
    /**
     * 
     * @type {Template}
     * @memberof Template
     */
    'orderTemplate': Template;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'name': string;
    /**
     * 
     * @type {Company}
     * @memberof Template
     */
    'company': Company;
}

export const TemplateProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type TemplateProductTypeEnum = typeof TemplateProductTypeEnum[keyof typeof TemplateProductTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAddressDto
 */
export interface UpdateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyAdminDto
 */
export interface UpdateCompanyAdminDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCompanyAdminDto
     */
    'availableProductTypes'?: Array<UpdateCompanyAdminDtoAvailableProductTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyAdminDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyAdminDto
     */
    'abacusId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyAdminDto
     */
    'hasLoyaltyBonus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyAdminDto
     */
    'deliveryFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyAdminDto
     */
    'deliveryFeeThreshold'?: number;
}

export const UpdateCompanyAdminDtoAvailableProductTypesEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type UpdateCompanyAdminDtoAvailableProductTypesEnum = typeof UpdateCompanyAdminDtoAvailableProductTypesEnum[keyof typeof UpdateCompanyAdminDtoAvailableProductTypesEnum];

/**
 * 
 * @export
 * @interface UpdateCompanyDto
 */
export interface UpdateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'defaultSlot12'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'defaultSlot12PerField'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDto
     */
    'defaultSlot13'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDto
     */
    'defaultSlot22'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDto
     */
    'defaultAddonWall'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDto
     */
    'AL_defaultAddonWall'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'AL_defaultSlot12'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'AL_defaultSlot12PerField'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDiscountPayload
 */
export interface UpdateDiscountPayload {
    /**
     * Type of the Discount
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'type': UpdateDiscountPayloadTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountPayload
     */
    'percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountPayload
     */
    'flat'?: number;
    /**
     * 
     * @type {Array<DiscountSeries>}
     * @memberof UpdateDiscountPayload
     */
    'series'?: Array<DiscountSeries>;
}

export const UpdateDiscountPayloadTypeEnum = {
    Percent: 'Percent',
    Flat: 'Flat',
    Series: 'Series'
} as const;

export type UpdateDiscountPayloadTypeEnum = typeof UpdateDiscountPayloadTypeEnum[keyof typeof UpdateDiscountPayloadTypeEnum];

/**
 * 
 * @export
 * @interface UpdateOrderDto
 */
export interface UpdateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'reference'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderDto
     */
    'depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'deliveryDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProductDto
 */
export interface UpdateProductDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductDto
     */
    'set'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'type'?: UpdateProductDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'isAddon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'isLabel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'slot'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductDto
     */
    'slots'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'basePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'baseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'sorting'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'abacusId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'internalNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'productNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'descriptionFr'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'additionalDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'additionalDescriptionFr'?: string;
}

export const UpdateProductDtoTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type UpdateProductDtoTypeEnum = typeof UpdateProductDtoTypeEnum[keyof typeof UpdateProductDtoTypeEnum];

/**
 * 
 * @export
 * @interface UpdateProductLineItemDto
 */
export interface UpdateProductLineItemDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'depth'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductLineItemDto
     */
    'override'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'custom'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'amount2'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductLineItemDto
     */
    'amount3'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'side'?: UpdateProductLineItemDtoSideEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'ipProtectionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'productNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'descriptionFr'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'additionalDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLineItemDto
     */
    'additionalDescriptionFr'?: string;
}

export const UpdateProductLineItemDtoSideEnum = {
    Left: 'Left',
    Right: 'Right'
} as const;

export type UpdateProductLineItemDtoSideEnum = typeof UpdateProductLineItemDtoSideEnum[keyof typeof UpdateProductLineItemDtoSideEnum];

/**
 * 
 * @export
 * @interface UpdateProductParentLineItemDto
 */
export interface UpdateProductParentLineItemDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductParentLineItemDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductParentLineItemDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductParentLineItemDto
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductParentLineItemDto
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface UpdatePromotionPayload
 */
export interface UpdatePromotionPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionPayload
     */
    'percent'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionPayload
     */
    'productType'?: UpdatePromotionPayloadProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionPayload
     */
    'maxUsage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionPayload
     */
    'maxCabins'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionPayload
     */
    'maxPercent'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionPayload
     */
    'endDate'?: string;
}

export const UpdatePromotionPayloadProductTypeEnum = {
    Ars: 'ARS',
    Avk: 'AVK',
    Al: 'AL',
    Swg: 'SWG',
    Ewg: 'EWG'
} as const;

export type UpdatePromotionPayloadProductTypeEnum = typeof UpdatePromotionPayloadProductTypeEnum[keyof typeof UpdatePromotionPayloadProductTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'newPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'newPasswordConfirmation'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'abacusId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updated': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedDay': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdDay': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'forcePasswordReset': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isSuperAdmin': boolean;
    /**
     * 
     * @type {Array<Order>}
     * @memberof User
     */
    'orders': Array<Order>;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'company': Company;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'adminOfCompany': Company;
}

/**
 * AIApi - axios parameter creator
 * @export
 */
export const AIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerGetJob: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aIControllerGetJob', 'id', id)
            const localVarPath = `/api/ai/job/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerGetJobs: async (limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('aIControllerGetJobs', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('aIControllerGetJobs', 'offset', offset)
            const localVarPath = `/api/ai/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerInit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ai/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerListen: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ai/listen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIApi - functional programming interface
 * @export
 */
export const AIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIControllerGetJob(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobLogResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIControllerGetJob(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIControllerGetJobs(limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIControllerGetJobs(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIControllerInit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIControllerInit(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIControllerListen(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIControllerListen(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AIApi - factory interface
 * @export
 */
export const AIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerGetJob(id: string, options?: any): AxiosPromise<JobLogResult> {
            return localVarFp.aIControllerGetJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerGetJobs(limit: number, offset: number, options?: any): AxiosPromise<JobResults> {
            return localVarFp.aIControllerGetJobs(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerInit(options?: any): AxiosPromise<void> {
            return localVarFp.aIControllerInit(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIControllerListen(options?: any): AxiosPromise<void> {
            return localVarFp.aIControllerListen(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AIApi - object-oriented interface
 * @export
 * @class AIApi
 * @extends {BaseAPI}
 */
export class AIApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIApi
     */
    public aIControllerGetJob(id: string, options?: AxiosRequestConfig) {
        return AIApiFp(this.configuration).aIControllerGetJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIApi
     */
    public aIControllerGetJobs(limit: number, offset: number, options?: AxiosRequestConfig) {
        return AIApiFp(this.configuration).aIControllerGetJobs(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIApi
     */
    public aIControllerInit(options?: AxiosRequestConfig) {
        return AIApiFp(this.configuration).aIControllerInit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIApi
     */
    public aIControllerListen(options?: AxiosRequestConfig) {
        return AIApiFp(this.configuration).aIControllerListen(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (forgotPassswordPayload: ForgotPassswordPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPassswordPayload' is not null or undefined
            assertParamExists('authControllerForgotPassword', 'forgotPassswordPayload', forgotPassswordPayload)
            const localVarPath = `/api/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPassswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetLoggedInUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginPayload: LoginPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginPayload' is not null or undefined
            assertParamExists('authControllerLogin', 'loginPayload', loginPayload)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister: async (registerPayload: RegisterPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPayload' is not null or undefined
            assertParamExists('authControllerRegister', 'registerPayload', registerPayload)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {RegisterWithTokenPayload} registerWithTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterWithToken: async (token: string, registerWithTokenPayload: RegisterWithTokenPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authControllerRegisterWithToken', 'token', token)
            // verify required parameter 'registerWithTokenPayload' is not null or undefined
            assertParamExists('authControllerRegisterWithToken', 'registerWithTokenPayload', registerWithTokenPayload)
            const localVarPath = `/api/auth/register/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerWithTokenPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordWithToken: async (resetPassswordPayload: ResetPassswordPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassswordPayload' is not null or undefined
            assertParamExists('authControllerResetPasswordWithToken', 'resetPassswordPayload', resetPassswordPayload)
            const localVarPath = `/api/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSuperAdminLogin: async (loginPayload: LoginPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginPayload' is not null or undefined
            assertParamExists('authControllerSuperAdminLogin', 'loginPayload', loginPayload)
            const localVarPath = `/api/auth/login/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgotPassword(forgotPassswordPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetLoggedInUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetLoggedInUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegister(registerPayload: RegisterPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegister(registerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {RegisterWithTokenPayload} registerWithTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterWithToken(token: string, registerWithTokenPayload: RegisterWithTokenPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterWithToken(token, registerWithTokenPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPasswordWithToken(resetPassswordPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSuperAdminLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSuperAdminLogin(loginPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.authControllerForgotPassword(forgotPassswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetLoggedInUser(options?: any): AxiosPromise<User> {
            return localVarFp.authControllerGetLoggedInUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginPayload: LoginPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerLogin(loginPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister(registerPayload: RegisterPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.authControllerRegister(registerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {RegisterWithTokenPayload} registerWithTokenPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterWithToken(token: string, registerWithTokenPayload: RegisterWithTokenPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerRegisterWithToken(token, registerWithTokenPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerResetPasswordWithToken(resetPassswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSuperAdminLogin(loginPayload: LoginPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerSuperAdminLogin(loginPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {ForgotPassswordPayload} forgotPassswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerForgotPassword(forgotPassswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerGetLoggedInUser(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerGetLoggedInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginPayload} loginPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerLogin(loginPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterPayload} registerPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerRegister(registerPayload: RegisterPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerRegister(registerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {RegisterWithTokenPayload} registerWithTokenPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerRegisterWithToken(token: string, registerWithTokenPayload: RegisterWithTokenPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerRegisterWithToken(token, registerWithTokenPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPassswordPayload} resetPassswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerResetPasswordWithToken(resetPassswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginPayload} loginPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerSuperAdminLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerSuperAdminLogin(loginPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminDeleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerAdminDeleteUser', 'id', id)
            const localVarPath = `/company/admin/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminRequestDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminUpdateCompanyUser: async (id: string, adminUpdateUserDto: AdminUpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerAdminUpdateCompanyUser', 'id', id)
            // verify required parameter 'adminUpdateUserDto' is not null or undefined
            assertParamExists('companyControllerAdminUpdateCompanyUser', 'adminUpdateUserDto', adminUpdateUserDto)
            const localVarPath = `/company/admin/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} abacus 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerConfirmCompany: async (id: string, abacus: string, offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerConfirmCompany', 'id', id)
            // verify required parameter 'abacus' is not null or undefined
            assertParamExists('companyControllerConfirmCompany', 'abacus', abacus)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerConfirmCompany', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerConfirmCompany', 'limit', limit)
            const localVarPath = `/company/admin/{id}/confirm/{abacus}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"abacus"}}`, encodeURIComponent(String(abacus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate: async (createCompanyDto: CreateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('companyControllerCreate', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreateCompanyAddress: async (createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('companyControllerCreateCompanyAddress', 'createAddressDto', createAddressDto)
            const localVarPath = `/company/my/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeclineCompany: async (id: string, offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerDeclineCompany', 'id', id)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerDeclineCompany', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerDeclineCompany', 'limit', limit)
            const localVarPath = `/company/admin/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeleteCompanyAddress: async (addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('companyControllerDeleteCompanyAddress', 'addressId', addressId)
            const localVarPath = `/company/my/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productNumber 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeleteDefaultProduct: async (productNumber: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productNumber' is not null or undefined
            assertParamExists('companyControllerDeleteDefaultProduct', 'productNumber', productNumber)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerDeleteDefaultProduct', 'id', id)
            const localVarPath = `/company/{id}/default/{productNumber}`
                .replace(`{${"productNumber"}}`, encodeURIComponent(String(productNumber)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllAddresses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/me/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {FindAllCompaniesDTO} findAllCompaniesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllCompanies: async (offset: number, limit: number, findAllCompaniesDTO: FindAllCompaniesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerFindAllCompanies', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerFindAllCompanies', 'limit', limit)
            // verify required parameter 'findAllCompaniesDTO' is not null or undefined
            assertParamExists('companyControllerFindAllCompanies', 'findAllCompaniesDTO', findAllCompaniesDTO)
            const localVarPath = `/company/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllCompaniesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllDeleteRequestedCompanies: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerFindAllDeleteRequestedCompanies', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerFindAllDeleteRequestedCompanies', 'limit', limit)
            const localVarPath = `/company/admin/deleterequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyOrdersDTO} findAllCompanyOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllOrders: async (limit: string, offset: string, findAllCompanyOrdersDTO: FindAllCompanyOrdersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerFindAllOrders', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerFindAllOrders', 'offset', offset)
            // verify required parameter 'findAllCompanyOrdersDTO' is not null or undefined
            assertParamExists('companyControllerFindAllOrders', 'findAllCompanyOrdersDTO', findAllCompanyOrdersDTO)
            const localVarPath = `/company/me/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllCompanyOrdersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllUnconfirmedCompanies: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerFindAllUnconfirmedCompanies', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerFindAllUnconfirmedCompanies', 'limit', limit)
            const localVarPath = `/company/admin/unconfirmed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyByTokenDTO} companyByTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyNameByToken: async (companyByTokenDTO: CompanyByTokenDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyByTokenDTO' is not null or undefined
            assertParamExists('companyControllerGetCompanyNameByToken', 'companyByTokenDTO', companyByTokenDTO)
            const localVarPath = `/company/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyByTokenDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteCompanyDto} inviteCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerInvite: async (inviteCompanyDto: InviteCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteCompanyDto' is not null or undefined
            assertParamExists('companyControllerInvite', 'inviteCompanyDto', inviteCompanyDto)
            const localVarPath = `/company/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerMyCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyUsersDTO} findAllCompanyUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerMyCompanyUsers: async (limit: string, offset: string, findAllCompanyUsersDTO: FindAllCompanyUsersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyControllerMyCompanyUsers', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('companyControllerMyCompanyUsers', 'offset', offset)
            // verify required parameter 'findAllCompanyUsersDTO' is not null or undefined
            assertParamExists('companyControllerMyCompanyUsers', 'findAllCompanyUsersDTO', findAllCompanyUsersDTO)
            const localVarPath = `/company/me/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllCompanyUsersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRemoveMultipleOrders: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('companyControllerRemoveMultipleOrders', 'requestBody', requestBody)
            const localVarPath = `/company/me/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRestoreMultipleOrders: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('companyControllerRestoreMultipleOrders', 'requestBody', requestBody)
            const localVarPath = `/company/me/order/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerSetAddressActive: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerSetAddressActive', 'id', id)
            const localVarPath = `/company/address/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DefaultProduct} defaultProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerSetDefaultProduct: async (id: string, defaultProduct: DefaultProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerSetDefaultProduct', 'id', id)
            // verify required parameter 'defaultProduct' is not null or undefined
            assertParamExists('companyControllerSetDefaultProduct', 'defaultProduct', defaultProduct)
            const localVarPath = `/company/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defaultProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate: async (id: string, updateCompanyDto: UpdateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerUpdate', 'id', id)
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('companyControllerUpdate', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyAdminDto} updateCompanyAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateAdmin: async (id: string, updateCompanyAdminDto: UpdateCompanyAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerUpdateAdmin', 'id', id)
            // verify required parameter 'updateCompanyAdminDto' is not null or undefined
            assertParamExists('companyControllerUpdateAdmin', 'updateCompanyAdminDto', updateCompanyAdminDto)
            const localVarPath = `/company/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateAdminAddress: async (id: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerUpdateAdminAddress', 'id', id)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('companyControllerUpdateAdminAddress', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/company/admin/address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyAddress: async (addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyAddress', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyAddress', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/company/my/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerAdminDeleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerAdminDeleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerAdminRequestDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerAdminRequestDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerAdminUpdateCompanyUser(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerAdminUpdateCompanyUser(id, adminUpdateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} abacus 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerConfirmCompany(id: string, abacus: string, offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerConfirmCompany(id, abacus, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreate(createCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCreateCompanyAddress(createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreateCompanyAddress(createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerDeclineCompany(id: string, offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerDeclineCompany(id, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerDeleteCompanyAddress(addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerDeleteCompanyAddress(addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productNumber 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerDeleteDefaultProduct(productNumber: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerDeleteDefaultProduct(productNumber, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAllAddresses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Address>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAllAddresses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {FindAllCompaniesDTO} findAllCompaniesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAllCompanies(offset: number, limit: number, findAllCompaniesDTO: FindAllCompaniesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAllCompanies(offset, limit, findAllCompaniesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAllDeleteRequestedCompanies(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAllDeleteRequestedCompanies(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyOrdersDTO} findAllCompanyOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAllOrders(limit: string, offset: string, findAllCompanyOrdersDTO: FindAllCompanyOrdersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllCompanyOrdersResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAllOrders(limit, offset, findAllCompanyOrdersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerFindAllUnconfirmedCompanies(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerFindAllUnconfirmedCompanies(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyByTokenDTO} companyByTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGetCompanyNameByToken(companyByTokenDTO: CompanyByTokenDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyNameByToken(companyByTokenDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InviteCompanyDto} inviteCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerInvite(inviteCompanyDto: InviteCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerInvite(inviteCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerMyCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerMyCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyUsersDTO} findAllCompanyUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerMyCompanyUsers(limit: string, offset: string, findAllCompanyUsersDTO: FindAllCompanyUsersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllCompanyUsersResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerMyCompanyUsers(limit, offset, findAllCompanyUsersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerRemoveMultipleOrders(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllCompanyOrdersResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerRemoveMultipleOrders(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerRestoreMultipleOrders(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllCompanyOrdersResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerRestoreMultipleOrders(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerSetAddressActive(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerSetAddressActive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DefaultProduct} defaultProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerSetDefaultProduct(id: string, defaultProduct: DefaultProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerSetDefaultProduct(id, defaultProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdate(id, updateCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyAdminDto} updateCompanyAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateAdmin(id: string, updateCompanyAdminDto: UpdateCompanyAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateAdmin(id, updateCompanyAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateAdminAddress(id: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateAdminAddress(id, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateCompanyAddress(addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompanyAddress(addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminDeleteUser(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.companyControllerAdminDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminRequestDelete(options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerAdminRequestDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerAdminUpdateCompanyUser(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.companyControllerAdminUpdateCompanyUser(id, adminUpdateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} abacus 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerConfirmCompany(id: string, abacus: string, offset: number, limit: number, options?: any): AxiosPromise<FindAndCountResultCompany> {
            return localVarFp.companyControllerConfirmCompany(id, abacus, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerCreate(createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreateCompanyAddress(createAddressDto: CreateAddressDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerCreateCompanyAddress(createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeclineCompany(id: string, offset: number, limit: number, options?: any): AxiosPromise<FindAndCountResultCompany> {
            return localVarFp.companyControllerDeclineCompany(id, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeleteCompanyAddress(addressId: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerDeleteCompanyAddress(addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productNumber 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDeleteDefaultProduct(productNumber: string, id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerDeleteDefaultProduct(productNumber, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllAddresses(options?: any): AxiosPromise<Array<Address>> {
            return localVarFp.companyControllerFindAllAddresses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {FindAllCompaniesDTO} findAllCompaniesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllCompanies(offset: number, limit: number, findAllCompaniesDTO: FindAllCompaniesDTO, options?: any): AxiosPromise<FindAndCountResultCompany> {
            return localVarFp.companyControllerFindAllCompanies(offset, limit, findAllCompaniesDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllDeleteRequestedCompanies(offset: number, limit: number, options?: any): AxiosPromise<FindAndCountResultCompany> {
            return localVarFp.companyControllerFindAllDeleteRequestedCompanies(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyOrdersDTO} findAllCompanyOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllOrders(limit: string, offset: string, findAllCompanyOrdersDTO: FindAllCompanyOrdersDTO, options?: any): AxiosPromise<FindAllCompanyOrdersResultDTO> {
            return localVarFp.companyControllerFindAllOrders(limit, offset, findAllCompanyOrdersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerFindAllUnconfirmedCompanies(offset: number, limit: number, options?: any): AxiosPromise<FindAndCountResultCompany> {
            return localVarFp.companyControllerFindAllUnconfirmedCompanies(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyByTokenDTO} companyByTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyNameByToken(companyByTokenDTO: CompanyByTokenDTO, options?: any): AxiosPromise<string> {
            return localVarFp.companyControllerGetCompanyNameByToken(companyByTokenDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteCompanyDto} inviteCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerInvite(inviteCompanyDto: InviteCompanyDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerInvite(inviteCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerMyCompany(options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerMyCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllCompanyUsersDTO} findAllCompanyUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerMyCompanyUsers(limit: string, offset: string, findAllCompanyUsersDTO: FindAllCompanyUsersDTO, options?: any): AxiosPromise<FindAllCompanyUsersResultDTO> {
            return localVarFp.companyControllerMyCompanyUsers(limit, offset, findAllCompanyUsersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRemoveMultipleOrders(requestBody: Array<string>, options?: any): AxiosPromise<FindAllCompanyOrdersResultDTO> {
            return localVarFp.companyControllerRemoveMultipleOrders(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerRestoreMultipleOrders(requestBody: Array<string>, options?: any): AxiosPromise<FindAllCompanyOrdersResultDTO> {
            return localVarFp.companyControllerRestoreMultipleOrders(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerSetAddressActive(id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerSetAddressActive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DefaultProduct} defaultProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerSetDefaultProduct(id: string, defaultProduct: DefaultProduct, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerSetDefaultProduct(id, defaultProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerUpdate(id, updateCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyAdminDto} updateCompanyAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateAdmin(id: string, updateCompanyAdminDto: UpdateCompanyAdminDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerUpdateAdmin(id, updateCompanyAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateAdminAddress(id: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<Address> {
            return localVarFp.companyControllerUpdateAdminAddress(id, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyAddress(addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerUpdateCompanyAddress(addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerAdminDeleteUser(id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerAdminDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerAdminRequestDelete(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerAdminRequestDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AdminUpdateUserDto} adminUpdateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerAdminUpdateCompanyUser(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerAdminUpdateCompanyUser(id, adminUpdateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} abacus 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerConfirmCompany(id: string, abacus: string, offset: number, limit: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerConfirmCompany(id, abacus, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCompanyDto} createCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerCreate(createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerCreateCompanyAddress(createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerCreateCompanyAddress(createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerDeclineCompany(id: string, offset: number, limit: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerDeclineCompany(id, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerDeleteCompanyAddress(addressId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerDeleteCompanyAddress(addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productNumber 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerDeleteDefaultProduct(productNumber: string, id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerDeleteDefaultProduct(productNumber, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAllAddresses(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAllAddresses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {FindAllCompaniesDTO} findAllCompaniesDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAllCompanies(offset: number, limit: number, findAllCompaniesDTO: FindAllCompaniesDTO, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAllCompanies(offset, limit, findAllCompaniesDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAllDeleteRequestedCompanies(offset: number, limit: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAllDeleteRequestedCompanies(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} limit 
     * @param {string} offset 
     * @param {FindAllCompanyOrdersDTO} findAllCompanyOrdersDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAllOrders(limit: string, offset: string, findAllCompanyOrdersDTO: FindAllCompanyOrdersDTO, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAllOrders(limit, offset, findAllCompanyOrdersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerFindAllUnconfirmedCompanies(offset: number, limit: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerFindAllUnconfirmedCompanies(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyByTokenDTO} companyByTokenDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerGetCompanyNameByToken(companyByTokenDTO: CompanyByTokenDTO, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerGetCompanyNameByToken(companyByTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteCompanyDto} inviteCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerInvite(inviteCompanyDto: InviteCompanyDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerInvite(inviteCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerMyCompany(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerMyCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} limit 
     * @param {string} offset 
     * @param {FindAllCompanyUsersDTO} findAllCompanyUsersDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerMyCompanyUsers(limit: string, offset: string, findAllCompanyUsersDTO: FindAllCompanyUsersDTO, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerMyCompanyUsers(limit, offset, findAllCompanyUsersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerRemoveMultipleOrders(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerRemoveMultipleOrders(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerRestoreMultipleOrders(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerRestoreMultipleOrders(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerSetAddressActive(id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerSetAddressActive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DefaultProduct} defaultProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerSetDefaultProduct(id: string, defaultProduct: DefaultProduct, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerSetDefaultProduct(id, defaultProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCompanyDto} updateCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdate(id, updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCompanyAdminDto} updateCompanyAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateAdmin(id: string, updateCompanyAdminDto: UpdateCompanyAdminDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateAdmin(id, updateCompanyAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateAdminAddress(id: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateAdminAddress(id, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateCompanyAddress(addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateCompanyAddress(addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountApi - axios parameter creator
 * @export
 */
export const DiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreate: async (companyId: string, createDiscountPayload: CreateDiscountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('discountControllerCreate', 'companyId', companyId)
            // verify required parameter 'createDiscountPayload' is not null or undefined
            assertParamExists('discountControllerCreate', 'createDiscountPayload', createDiscountPayload)
            const localVarPath = `/api/company/{companyId}/discount`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiscountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerDelete: async (companyId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('discountControllerDelete', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerDelete', 'id', id)
            const localVarPath = `/api/company/{companyId}/discount/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdate: async (companyId: string, id: string, updateDiscountPayload: UpdateDiscountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('discountControllerUpdate', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerUpdate', 'id', id)
            // verify required parameter 'updateDiscountPayload' is not null or undefined
            assertParamExists('discountControllerUpdate', 'updateDiscountPayload', updateDiscountPayload)
            const localVarPath = `/api/company/{companyId}/discount/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiscountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountApi - functional programming interface
 * @export
 */
export const DiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerCreate(companyId: string, createDiscountPayload: CreateDiscountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerCreate(companyId, createDiscountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerDelete(companyId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerDelete(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerUpdate(companyId: string, id: string, updateDiscountPayload: UpdateDiscountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerUpdate(companyId, id, updateDiscountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountApi - factory interface
 * @export
 */
export const DiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreate(companyId: string, createDiscountPayload: CreateDiscountPayload, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerCreate(companyId, createDiscountPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerDelete(companyId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.discountControllerDelete(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} id 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdate(companyId: string, id: string, updateDiscountPayload: UpdateDiscountPayload, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerUpdate(companyId, id, updateDiscountPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountApi - object-oriented interface
 * @export
 * @class DiscountApi
 * @extends {BaseAPI}
 */
export class DiscountApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {CreateDiscountPayload} createDiscountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerCreate(companyId: string, createDiscountPayload: CreateDiscountPayload, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerCreate(companyId, createDiscountPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerDelete(companyId: string, id: string, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerDelete(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} id 
     * @param {UpdateDiscountPayload} updateDiscountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public discountControllerUpdate(companyId: string, id: string, updateDiscountPayload: UpdateDiscountPayload, options?: AxiosRequestConfig) {
        return DiscountApiFp(this.configuration).discountControllerUpdate(companyId, id, updateDiscountPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerCreate: async (createFilePayload: CreateFilePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFilePayload' is not null or undefined
            assertParamExists('fileControllerCreate', 'createFilePayload', createFilePayload)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFilePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerCreate(createFilePayload: CreateFilePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerCreate(createFilePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerCreate(createFilePayload: CreateFilePayload, options?: any): AxiosPromise<any> {
            return localVarFp.fileControllerCreate(createFilePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {CreateFilePayload} createFilePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerCreate(createFilePayload: CreateFilePayload, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerCreate(createFilePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealth(options?: any): AxiosPromise<void> {
            return localVarFp.appControllerHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public appControllerHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).appControllerHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAVKSwitchCompartments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAVKSwitchCompartments', 'id', id)
            const localVarPath = `/order/{id}/switch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddAddonProduct: async (id: string, createProductLineItemDto: CreateProductLineItemDto, lineItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddAddonProduct', 'id', id)
            // verify required parameter 'createProductLineItemDto' is not null or undefined
            assertParamExists('orderControllerAddAddonProduct', 'createProductLineItemDto', createProductLineItemDto)
            const localVarPath = `/order/{id}/addon/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lineItemId !== undefined) {
                localVarQueryParameter['lineItemId'] = lineItemId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductLineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddAddonProductByName: async (id: string, productNumber: string, lineItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddAddonProductByName', 'id', id)
            // verify required parameter 'productNumber' is not null or undefined
            assertParamExists('orderControllerAddAddonProductByName', 'productNumber', productNumber)
            const localVarPath = `/order/{id}/addon/add/{productNumber}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"productNumber"}}`, encodeURIComponent(String(productNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lineItemId !== undefined) {
                localVarQueryParameter['lineItemId'] = lineItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultDividerWall: async (id: string, sort: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddDefaultDividerWall', 'id', id)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('orderControllerAddDefaultDividerWall', 'sort', sort)
            const localVarPath = `/order/{id}/dividerwall`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultSocket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddDefaultSocket', 'id', id)
            const localVarPath = `/order/{id}/socket`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultWall: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddDefaultWall', 'id', id)
            const localVarPath = `/order/{id}/wall`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddFileToOrder: async (id: string, createFilePayload: CreateFilePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddFileToOrder', 'id', id)
            // verify required parameter 'createFilePayload' is not null or undefined
            assertParamExists('orderControllerAddFileToOrder', 'createFilePayload', createFilePayload)
            const localVarPath = `/order/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFilePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItem: async (id: string, createProductLineItemDto: CreateProductLineItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddLineItem', 'id', id)
            // verify required parameter 'createProductLineItemDto' is not null or undefined
            assertParamExists('orderControllerAddLineItem', 'createProductLineItemDto', createProductLineItemDto)
            const localVarPath = `/order/{id}/lineitem`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductLineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItemByName: async (id: string, productNumber: string, sorting: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddLineItemByName', 'id', id)
            // verify required parameter 'productNumber' is not null or undefined
            assertParamExists('orderControllerAddLineItemByName', 'productNumber', productNumber)
            // verify required parameter 'sorting' is not null or undefined
            assertParamExists('orderControllerAddLineItemByName', 'sorting', sorting)
            const localVarPath = `/order/{id}/lineitem/{productNumber}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"productNumber"}}`, encodeURIComponent(String(productNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItemSlot: async (id: string, slot: string, lineItemId: string, createProductLineItemDto: CreateProductLineItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddLineItemSlot', 'id', id)
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('orderControllerAddLineItemSlot', 'slot', slot)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerAddLineItemSlot', 'lineItemId', lineItemId)
            // verify required parameter 'createProductLineItemDto' is not null or undefined
            assertParamExists('orderControllerAddLineItemSlot', 'createProductLineItemDto', createProductLineItemDto)
            const localVarPath = `/order/{id}/lineitem/{lineItemId}/{slot}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductLineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddPromotion: async (id: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddPromotion', 'id', id)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('orderControllerAddPromotion', 'code', code)
            const localVarPath = `/order/{id}/promotion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateId 
         * @param {string} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddTemplate: async (id: string, templateId: string, sorting: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAddTemplate', 'id', id)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('orderControllerAddTemplate', 'templateId', templateId)
            // verify required parameter 'sorting' is not null or undefined
            assertParamExists('orderControllerAddTemplate', 'sorting', sorting)
            const localVarPath = `/order/{id}/template/{templateId}/{sorting}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"sorting"}}`, encodeURIComponent(String(sorting)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAdminViewPDF: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerAdminViewPDF', 'id', id)
            const localVarPath = `/order/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCalcOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerCalcOrder', 'id', id)
            const localVarPath = `/order/{id}/calc`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate: async (createOrderDto: CreateOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderDto' is not null or undefined
            assertParamExists('orderControllerCreate', 'createOrderDto', createOrderDto)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderByTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('orderControllerCreateOrderByTemplate', 'templateId', templateId)
            const localVarPath = `/order/template/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAll: async (offset: string, limit: string, findAllOrdersDTO: FindAllOrdersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('orderControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('orderControllerFindAll', 'limit', limit)
            // verify required parameter 'findAllOrdersDTO' is not null or undefined
            assertParamExists('orderControllerFindAll', 'findAllOrdersDTO', findAllOrdersDTO)
            const localVarPath = `/order/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllOrdersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAllCount: async (findAllOrdersDTO: FindAllOrdersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findAllOrdersDTO' is not null or undefined
            assertParamExists('orderControllerFindAllCount', 'findAllOrdersDTO', findAllOrdersDTO)
            const localVarPath = `/order/admin/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllOrdersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerFindOne', 'id', id)
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFinishAdminOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerFinishAdminOrder', 'id', id)
            const localVarPath = `/order/{id}/admin/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFinishOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerFinishOrder', 'id', id)
            const localVarPath = `/order/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateOffer: async (id: string, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerGenerateOffer', 'id', id)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('orderControllerGenerateOffer', 'lang', lang)
            const localVarPath = `/order/{id}/offer/{lang}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateOverview: async (id: string, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerGenerateOverview', 'id', id)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('orderControllerGenerateOverview', 'lang', lang)
            const localVarPath = `/order/{id}/overview/{lang}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sorting 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPasteLineItem: async (id: string, sorting: string, lineItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerPasteLineItem', 'id', id)
            // verify required parameter 'sorting' is not null or undefined
            assertParamExists('orderControllerPasteLineItem', 'sorting', sorting)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerPasteLineItem', 'lineItemId', lineItemId)
            const localVarPath = `/order/{id}/lineitem/paste/{lineItemId}/{sorting}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"sorting"}}`, encodeURIComponent(String(sorting)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {number} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveAddonProduct: async (id: string, lineItemId: string, slot: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveAddonProduct', 'id', id)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerRemoveAddonProduct', 'lineItemId', lineItemId)
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('orderControllerRemoveAddonProduct', 'slot', slot)
            const localVarPath = `/order/{id}/addon/{lineItemId}/{slot}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)))
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveBillingAddress: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveBillingAddress', 'id', id)
            const localVarPath = `/order/{id}/address/billing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveDeliveryAddress: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveDeliveryAddress', 'id', id)
            const localVarPath = `/order/{id}/address/delivery`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveFileFromOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveFileFromOrder', 'id', id)
            const localVarPath = `/order/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveLineItem: async (id: string, lineItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveLineItem', 'id', id)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerRemoveLineItem', 'lineItemId', lineItemId)
            const localVarPath = `/order/{id}/lineitem/{lineItemId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveLineItemSlot: async (id: string, slot: string, lineItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemoveLineItemSlot', 'id', id)
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('orderControllerRemoveLineItemSlot', 'slot', slot)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerRemoveLineItemSlot', 'lineItemId', lineItemId)
            const localVarPath = `/order/{id}/lineitem/{lineItemId}/{slot}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemovePromotion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerRemovePromotion', 'id', id)
            const localVarPath = `/order/{id}/promotion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetAddonProductsDTO} setAddonProductsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetAddonProducts: async (id: string, setAddonProductsDTO: SetAddonProductsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerSetAddonProducts', 'id', id)
            // verify required parameter 'setAddonProductsDTO' is not null or undefined
            assertParamExists('orderControllerSetAddonProducts', 'setAddonProductsDTO', setAddonProductsDTO)
            const localVarPath = `/order/{id}/addon`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAddonProductsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetBillingAddress: async (id: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerSetBillingAddress', 'id', id)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orderControllerSetBillingAddress', 'addressId', addressId)
            const localVarPath = `/order/{id}/address/billing/{addressId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetDeliveryAddress: async (id: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerSetDeliveryAddress', 'id', id)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('orderControllerSetDeliveryAddress', 'addressId', addressId)
            const localVarPath = `/order/{id}/address/delivery/{addressId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSortOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerSortOrder', 'id', id)
            const localVarPath = `/order/{id}/sort`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdate: async (id: string, updateOrderDto: UpdateOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdate', 'id', id)
            // verify required parameter 'updateOrderDto' is not null or undefined
            assertParamExists('orderControllerUpdate', 'updateOrderDto', updateOrderDto)
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {UpdateProductParentLineItemDto} updateProductParentLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateLineItem: async (id: string, lineItemId: string, updateProductParentLineItemDto: UpdateProductParentLineItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdateLineItem', 'id', id)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerUpdateLineItem', 'lineItemId', lineItemId)
            // verify required parameter 'updateProductParentLineItemDto' is not null or undefined
            assertParamExists('orderControllerUpdateLineItem', 'updateProductParentLineItemDto', updateProductParentLineItemDto)
            const localVarPath = `/order/{id}/lineitem/{lineItemId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductParentLineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {UpdateProductLineItemDto} updateProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateLineItemSlot: async (id: string, slot: string, lineItemId: string, updateProductLineItemDto: UpdateProductLineItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdateLineItemSlot', 'id', id)
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('orderControllerUpdateLineItemSlot', 'slot', slot)
            // verify required parameter 'lineItemId' is not null or undefined
            assertParamExists('orderControllerUpdateLineItemSlot', 'lineItemId', lineItemId)
            // verify required parameter 'updateProductLineItemDto' is not null or undefined
            assertParamExists('orderControllerUpdateLineItemSlot', 'updateProductLineItemDto', updateProductLineItemDto)
            const localVarPath = `/order/{id}/lineitem/{lineItemId}/{slot}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)))
                .replace(`{${"lineItemId"}}`, encodeURIComponent(String(lineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductLineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerXmlExport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerXmlExport', 'id', id)
            const localVarPath = `/order/{id}/xml`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAVKSwitchCompartments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAVKSwitchCompartments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddAddonProduct(id: string, createProductLineItemDto: CreateProductLineItemDto, lineItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddAddonProduct(id, createProductLineItemDto, lineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddAddonProductByName(id: string, productNumber: string, lineItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddAddonProductByName(id, productNumber, lineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddDefaultDividerWall(id: string, sort: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddDefaultDividerWall(id, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddDefaultSocket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddDefaultSocket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddDefaultWall(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddDefaultWall(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddFileToOrder(id: string, createFilePayload: CreateFilePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddFileToOrder(id, createFilePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddLineItem(id: string, createProductLineItemDto: CreateProductLineItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddLineItem(id, createProductLineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddLineItemByName(id: string, productNumber: string, sorting: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddLineItemByName(id, productNumber, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddLineItemSlot(id: string, slot: string, lineItemId: string, createProductLineItemDto: CreateProductLineItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddLineItemSlot(id, slot, lineItemId, createProductLineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddPromotion(id: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddPromotion(id, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateId 
         * @param {string} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAddTemplate(id: string, templateId: string, sorting: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAddTemplate(id, templateId, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAdminViewPDF(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAdminViewPDF(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCalcOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalcResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCalcOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreate(createOrderDto: CreateOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreate(createOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOrderByTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOrderByTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindAll(offset: string, limit: string, findAllOrdersDTO: FindAllOrdersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindAll(offset, limit, findAllOrdersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindAllCount(findAllOrdersDTO: FindAllOrdersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindAllCount(findAllOrdersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFinishAdminOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFinishAdminOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFinishOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFinishOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGenerateOffer(id: string, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGenerateOffer(id, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGenerateOverview(id: string, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGenerateOverview(id, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sorting 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerPasteLineItem(id: string, sorting: string, lineItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerPasteLineItem(id, sorting, lineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {number} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveAddonProduct(id: string, lineItemId: string, slot: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveAddonProduct(id, lineItemId, slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveBillingAddress(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveBillingAddress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveDeliveryAddress(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveDeliveryAddress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveFileFromOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveFileFromOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveLineItem(id: string, lineItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveLineItem(id, lineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemoveLineItemSlot(id: string, slot: string, lineItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemoveLineItemSlot(id, slot, lineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerRemovePromotion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerRemovePromotion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SetAddonProductsDTO} setAddonProductsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSetAddonProducts(id: string, setAddonProductsDTO: SetAddonProductsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSetAddonProducts(id, setAddonProductsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSetBillingAddress(id: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSetBillingAddress(id, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSetDeliveryAddress(id: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSetDeliveryAddress(id, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSortOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSortOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdate(id: string, updateOrderDto: UpdateOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdate(id, updateOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {UpdateProductParentLineItemDto} updateProductParentLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateLineItem(id: string, lineItemId: string, updateProductParentLineItemDto: UpdateProductParentLineItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateLineItem(id, lineItemId, updateProductParentLineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {UpdateProductLineItemDto} updateProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateLineItemSlot(id: string, slot: string, lineItemId: string, updateProductLineItemDto: UpdateProductLineItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateLineItemSlot(id, slot, lineItemId, updateProductLineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerXmlExport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerXmlExport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAVKSwitchCompartments(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAVKSwitchCompartments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddAddonProduct(id: string, createProductLineItemDto: CreateProductLineItemDto, lineItemId?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddAddonProduct(id, createProductLineItemDto, lineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {string} [lineItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddAddonProductByName(id: string, productNumber: string, lineItemId?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddAddonProductByName(id, productNumber, lineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultDividerWall(id: string, sort: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddDefaultDividerWall(id, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultSocket(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddDefaultSocket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddDefaultWall(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddDefaultWall(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddFileToOrder(id: string, createFilePayload: CreateFilePayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddFileToOrder(id, createFilePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItem(id: string, createProductLineItemDto: CreateProductLineItemDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddLineItem(id, createProductLineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} productNumber 
         * @param {number} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItemByName(id: string, productNumber: string, sorting: number, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddLineItemByName(id, productNumber, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {CreateProductLineItemDto} createProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddLineItemSlot(id: string, slot: string, lineItemId: string, createProductLineItemDto: CreateProductLineItemDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddLineItemSlot(id, slot, lineItemId, createProductLineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddPromotion(id: string, code: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddPromotion(id, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} templateId 
         * @param {string} sorting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAddTemplate(id: string, templateId: string, sorting: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerAddTemplate(id, templateId, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAdminViewPDF(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderControllerAdminViewPDF(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCalcOrder(id: string, options?: any): AxiosPromise<CalcResult> {
            return localVarFp.orderControllerCalcOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate(createOrderDto: CreateOrderDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreate(createOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderByTemplate(templateId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreateOrderByTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAll(offset: string, limit: string, findAllOrdersDTO: FindAllOrdersDTO, options?: any): AxiosPromise<FindAndCountResultOrder> {
            return localVarFp.orderControllerFindAll(offset, limit, findAllOrdersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindAllOrdersDTO} findAllOrdersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAllCount(findAllOrdersDTO: FindAllOrdersDTO, options?: any): AxiosPromise<number> {
            return localVarFp.orderControllerFindAllCount(findAllOrdersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindOne(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFinishAdminOrder(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerFinishAdminOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFinishOrder(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerFinishOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateOffer(id: string, lang: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderControllerGenerateOffer(id, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateOverview(id: string, lang: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderControllerGenerateOverview(id, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} sorting 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPasteLineItem(id: string, sorting: string, lineItemId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerPasteLineItem(id, sorting, lineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {number} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveAddonProduct(id: string, lineItemId: string, slot: number, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveAddonProduct(id, lineItemId, slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveBillingAddress(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveBillingAddress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveDeliveryAddress(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveDeliveryAddress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveFileFromOrder(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveFileFromOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveLineItem(id: string, lineItemId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveLineItem(id, lineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemoveLineItemSlot(id: string, slot: string, lineItemId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemoveLineItemSlot(id, slot, lineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerRemovePromotion(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerRemovePromotion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SetAddonProductsDTO} setAddonProductsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetAddonProducts(id: string, setAddonProductsDTO: SetAddonProductsDTO, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSetAddonProducts(id, setAddonProductsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetBillingAddress(id: string, addressId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSetBillingAddress(id, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSetDeliveryAddress(id: string, addressId: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSetDeliveryAddress(id, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSortOrder(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSortOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdate(id: string, updateOrderDto: UpdateOrderDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdate(id, updateOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} lineItemId 
         * @param {UpdateProductParentLineItemDto} updateProductParentLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateLineItem(id: string, lineItemId: string, updateProductParentLineItemDto: UpdateProductParentLineItemDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateLineItem(id, lineItemId, updateProductParentLineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} slot 
         * @param {string} lineItemId 
         * @param {UpdateProductLineItemDto} updateProductLineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateLineItemSlot(id: string, slot: string, lineItemId: string, updateProductLineItemDto: UpdateProductLineItemDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateLineItemSlot(id, slot, lineItemId, updateProductLineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerXmlExport(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.orderControllerXmlExport(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAVKSwitchCompartments(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAVKSwitchCompartments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateProductLineItemDto} createProductLineItemDto 
     * @param {string} [lineItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddAddonProduct(id: string, createProductLineItemDto: CreateProductLineItemDto, lineItemId?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddAddonProduct(id, createProductLineItemDto, lineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} productNumber 
     * @param {string} [lineItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddAddonProductByName(id: string, productNumber: string, lineItemId?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddAddonProductByName(id, productNumber, lineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} sort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddDefaultDividerWall(id: string, sort: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddDefaultDividerWall(id, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddDefaultSocket(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddDefaultSocket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddDefaultWall(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddDefaultWall(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateFilePayload} createFilePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddFileToOrder(id: string, createFilePayload: CreateFilePayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddFileToOrder(id, createFilePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateProductLineItemDto} createProductLineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddLineItem(id: string, createProductLineItemDto: CreateProductLineItemDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddLineItem(id, createProductLineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} productNumber 
     * @param {number} sorting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddLineItemByName(id: string, productNumber: string, sorting: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddLineItemByName(id, productNumber, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} slot 
     * @param {string} lineItemId 
     * @param {CreateProductLineItemDto} createProductLineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddLineItemSlot(id: string, slot: string, lineItemId: string, createProductLineItemDto: CreateProductLineItemDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddLineItemSlot(id, slot, lineItemId, createProductLineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddPromotion(id: string, code: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddPromotion(id, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} templateId 
     * @param {string} sorting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAddTemplate(id: string, templateId: string, sorting: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAddTemplate(id, templateId, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAdminViewPDF(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAdminViewPDF(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCalcOrder(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCalcOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderDto} createOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreate(createOrderDto: CreateOrderDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreate(createOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOrderByTemplate(templateId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOrderByTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offset 
     * @param {string} limit 
     * @param {FindAllOrdersDTO} findAllOrdersDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindAll(offset: string, limit: string, findAllOrdersDTO: FindAllOrdersDTO, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindAll(offset, limit, findAllOrdersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindAllOrdersDTO} findAllOrdersDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindAllCount(findAllOrdersDTO: FindAllOrdersDTO, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindAllCount(findAllOrdersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFinishAdminOrder(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFinishAdminOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFinishOrder(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFinishOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGenerateOffer(id: string, lang: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGenerateOffer(id, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGenerateOverview(id: string, lang: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGenerateOverview(id, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} sorting 
     * @param {string} lineItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerPasteLineItem(id: string, sorting: string, lineItemId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerPasteLineItem(id, sorting, lineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} lineItemId 
     * @param {number} slot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveAddonProduct(id: string, lineItemId: string, slot: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveAddonProduct(id, lineItemId, slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveBillingAddress(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveBillingAddress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveDeliveryAddress(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveDeliveryAddress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveFileFromOrder(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveFileFromOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} lineItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveLineItem(id: string, lineItemId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveLineItem(id, lineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} slot 
     * @param {string} lineItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemoveLineItemSlot(id: string, slot: string, lineItemId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemoveLineItemSlot(id, slot, lineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerRemovePromotion(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerRemovePromotion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SetAddonProductsDTO} setAddonProductsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSetAddonProducts(id: string, setAddonProductsDTO: SetAddonProductsDTO, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSetAddonProducts(id, setAddonProductsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSetBillingAddress(id: string, addressId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSetBillingAddress(id, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSetDeliveryAddress(id: string, addressId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSetDeliveryAddress(id, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSortOrder(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSortOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOrderDto} updateOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdate(id: string, updateOrderDto: UpdateOrderDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdate(id, updateOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} lineItemId 
     * @param {UpdateProductParentLineItemDto} updateProductParentLineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateLineItem(id: string, lineItemId: string, updateProductParentLineItemDto: UpdateProductParentLineItemDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateLineItem(id, lineItemId, updateProductParentLineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} slot 
     * @param {string} lineItemId 
     * @param {UpdateProductLineItemDto} updateProductLineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateLineItemSlot(id: string, slot: string, lineItemId: string, updateProductLineItemDto: UpdateProductLineItemDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateLineItemSlot(id, slot, lineItemId, updateProductLineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerXmlExport(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerXmlExport(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderAggregationApi - axios parameter creator
 * @export
 */
export const OrderAggregationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CSVDownloadDto} cSVDownloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAggregationControllerGetCSV: async (cSVDownloadDto: CSVDownloadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cSVDownloadDto' is not null or undefined
            assertParamExists('orderAggregationControllerGetCSV', 'cSVDownloadDto', cSVDownloadDto)
            const localVarPath = `/order/aggregation/price/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cSVDownloadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateDto} aggregateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAggregationControllerGetPriceAggr: async (aggregateDto: AggregateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateDto' is not null or undefined
            assertParamExists('orderAggregationControllerGetPriceAggr', 'aggregateDto', aggregateDto)
            const localVarPath = `/order/aggregation/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderAggregationApi - functional programming interface
 * @export
 */
export const OrderAggregationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderAggregationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CSVDownloadDto} cSVDownloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderAggregationControllerGetCSV(cSVDownloadDto: CSVDownloadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderAggregationControllerGetCSV(cSVDownloadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AggregateDto} aggregateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderAggregationControllerGetPriceAggr(aggregateDto: AggregateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderAggregationControllerGetPriceAggr(aggregateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderAggregationApi - factory interface
 * @export
 */
export const OrderAggregationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderAggregationApiFp(configuration)
    return {
        /**
         * 
         * @param {CSVDownloadDto} cSVDownloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAggregationControllerGetCSV(cSVDownloadDto: CSVDownloadDto, options?: any): AxiosPromise<string> {
            return localVarFp.orderAggregationControllerGetCSV(cSVDownloadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateDto} aggregateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAggregationControllerGetPriceAggr(aggregateDto: AggregateDto, options?: any): AxiosPromise<AggregationResult> {
            return localVarFp.orderAggregationControllerGetPriceAggr(aggregateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderAggregationApi - object-oriented interface
 * @export
 * @class OrderAggregationApi
 * @extends {BaseAPI}
 */
export class OrderAggregationApi extends BaseAPI {
    /**
     * 
     * @param {CSVDownloadDto} cSVDownloadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAggregationApi
     */
    public orderAggregationControllerGetCSV(cSVDownloadDto: CSVDownloadDto, options?: AxiosRequestConfig) {
        return OrderAggregationApiFp(this.configuration).orderAggregationControllerGetCSV(cSVDownloadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateDto} aggregateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderAggregationApi
     */
    public orderAggregationControllerGetPriceAggr(aggregateDto: AggregateDto, options?: AxiosRequestConfig) {
        return OrderAggregationApiFp(this.configuration).orderAggregationControllerGetPriceAggr(aggregateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceConfigApi - axios parameter creator
 * @export
 */
export const PriceConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllPriceConfigsDTO} findAllPriceConfigsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerFindAllPriceConfigs: async (limit: string, offset: string, findAllPriceConfigsDTO: FindAllPriceConfigsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('priceConfigControllerFindAllPriceConfigs', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('priceConfigControllerFindAllPriceConfigs', 'offset', offset)
            // verify required parameter 'findAllPriceConfigsDTO' is not null or undefined
            assertParamExists('priceConfigControllerFindAllPriceConfigs', 'findAllPriceConfigsDTO', findAllPriceConfigsDTO)
            const localVarPath = `/price-config/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllPriceConfigsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerFindAllPriceErrors: async (limit: string, offset: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('priceConfigControllerFindAllPriceErrors', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('priceConfigControllerFindAllPriceErrors', 'offset', offset)
            const localVarPath = `/price-config/errors/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitARSSeeding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/price-config/seeding/ars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitSWGSeeding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/price-config/seeding/swg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitSeeding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/price-config/seeding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG'} type 
         * @param {number} [depth] 
         * @param {number} [colLength] 
         * @param {boolean} [ignoreLastColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerStringParse: async (type: 'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG', depth?: number, colLength?: number, ignoreLastColumn?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('priceConfigControllerStringParse', 'type', type)
            const localVarPath = `/price-config/stringparse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (colLength !== undefined) {
                localVarQueryParameter['colLength'] = colLength;
            }

            if (ignoreLastColumn !== undefined) {
                localVarQueryParameter['ignoreLastColumn'] = ignoreLastColumn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LookupDTO} lookupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerTestLookup: async (lookupDTO: LookupDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookupDTO' is not null or undefined
            assertParamExists('priceConfigControllerTestLookup', 'lookupDTO', lookupDTO)
            const localVarPath = `/price-config/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lookupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceConfigApi - functional programming interface
 * @export
 */
export const PriceConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllPriceConfigsDTO} findAllPriceConfigsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerFindAllPriceConfigs(limit: string, offset: string, findAllPriceConfigsDTO: FindAllPriceConfigsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPriceConfigsResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerFindAllPriceConfigs(limit, offset, findAllPriceConfigsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerFindAllPriceErrors(limit: string, offset: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPriceErrorsResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerFindAllPriceErrors(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerInitARSSeeding(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerInitARSSeeding(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerInitSWGSeeding(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerInitSWGSeeding(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerInitSeeding(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerInitSeeding(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG'} type 
         * @param {number} [depth] 
         * @param {number} [colLength] 
         * @param {boolean} [ignoreLastColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerStringParse(type: 'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG', depth?: number, colLength?: number, ignoreLastColumn?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerStringParse(type, depth, colLength, ignoreLastColumn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LookupDTO} lookupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceConfigControllerTestLookup(lookupDTO: LookupDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceConfigControllerTestLookup(lookupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceConfigApi - factory interface
 * @export
 */
export const PriceConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllPriceConfigsDTO} findAllPriceConfigsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerFindAllPriceConfigs(limit: string, offset: string, findAllPriceConfigsDTO: FindAllPriceConfigsDTO, options?: any): AxiosPromise<FindAllPriceConfigsResultDTO> {
            return localVarFp.priceConfigControllerFindAllPriceConfigs(limit, offset, findAllPriceConfigsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerFindAllPriceErrors(limit: string, offset: string, options?: any): AxiosPromise<FindAllPriceErrorsResultDTO> {
            return localVarFp.priceConfigControllerFindAllPriceErrors(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitARSSeeding(options?: any): AxiosPromise<void> {
            return localVarFp.priceConfigControllerInitARSSeeding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitSWGSeeding(options?: any): AxiosPromise<void> {
            return localVarFp.priceConfigControllerInitSWGSeeding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerInitSeeding(options?: any): AxiosPromise<void> {
            return localVarFp.priceConfigControllerInitSeeding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG'} type 
         * @param {number} [depth] 
         * @param {number} [colLength] 
         * @param {boolean} [ignoreLastColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerStringParse(type: 'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG', depth?: number, colLength?: number, ignoreLastColumn?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.priceConfigControllerStringParse(type, depth, colLength, ignoreLastColumn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LookupDTO} lookupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceConfigControllerTestLookup(lookupDTO: LookupDTO, options?: any): AxiosPromise<void> {
            return localVarFp.priceConfigControllerTestLookup(lookupDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceConfigApi - object-oriented interface
 * @export
 * @class PriceConfigApi
 * @extends {BaseAPI}
 */
export class PriceConfigApi extends BaseAPI {
    /**
     * 
     * @param {string} limit 
     * @param {string} offset 
     * @param {FindAllPriceConfigsDTO} findAllPriceConfigsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerFindAllPriceConfigs(limit: string, offset: string, findAllPriceConfigsDTO: FindAllPriceConfigsDTO, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerFindAllPriceConfigs(limit, offset, findAllPriceConfigsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} limit 
     * @param {string} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerFindAllPriceErrors(limit: string, offset: string, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerFindAllPriceErrors(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerInitARSSeeding(options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerInitARSSeeding(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerInitSWGSeeding(options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerInitSWGSeeding(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerInitSeeding(options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerInitSeeding(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG'} type 
     * @param {number} [depth] 
     * @param {number} [colLength] 
     * @param {boolean} [ignoreLastColumn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerStringParse(type: 'ARS' | 'AVK' | 'AL' | 'SWG' | 'EWG', depth?: number, colLength?: number, ignoreLastColumn?: boolean, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerStringParse(type, depth, colLength, ignoreLastColumn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LookupDTO} lookupDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceConfigControllerTestLookup(lookupDTO: LookupDTO, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceConfigControllerTestLookup(lookupDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerCount: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('productControllerCount', 'type', type)
            const localVarPath = `/product/count/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerCreate: async (createProductDto: CreateProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductDto' is not null or undefined
            assertParamExists('productControllerCreate', 'createProductDto', createProductDto)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllAddonProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/addon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllAddonProductsForProduct: async (prod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prod' is not null or undefined
            assertParamExists('productControllerFindAllAddonProductsForProduct', 'prod', prod)
            const localVarPath = `/product/{prod}/addon`
                .replace(`{${"prod"}}`, encodeURIComponent(String(prod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllMainProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/main`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlot: async (slot: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlot', 'slot', slot)
            const localVarPath = `/product/set/{slot}`
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slot 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlotForProduct: async (slot: string, prod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slot' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlotForProduct', 'slot', slot)
            // verify required parameter 'prod' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlotForProduct', 'prod', prod)
            const localVarPath = `/product/{prod}/set/{slot}`
                .replace(`{${"slot"}}`, encodeURIComponent(String(slot)))
                .replace(`{${"prod"}}`, encodeURIComponent(String(prod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlots: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlots', 'requestBody', requestBody)
            const localVarPath = `/product/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prod 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlotsForProduct: async (prod: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prod' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlotsForProduct', 'prod', prod)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('productControllerFindAllSetProductsBySlotsForProduct', 'requestBody', requestBody)
            const localVarPath = `/product/{prod}/set`
                .replace(`{${"prod"}}`, encodeURIComponent(String(prod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productControllerFindOne', 'id', id)
            const localVarPath = `/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetDefaultSocket: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/default/socket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetDefaultSocketForProduct: async (prod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prod' is not null or undefined
            assertParamExists('productControllerGetDefaultSocketForProduct', 'prod', prod)
            const localVarPath = `/product/{prod}/default/socket`
                .replace(`{${"prod"}}`, encodeURIComponent(String(prod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetLabelProduct: async (prod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prod' is not null or undefined
            assertParamExists('productControllerGetLabelProduct', 'prod', prod)
            const localVarPath = `/product/{prod}/label`
                .replace(`{${"prod"}}`, encodeURIComponent(String(prod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportAlAddons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/al/addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportJsonProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportSwgAddons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/swg/addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} upsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportTestXML: async (upsert: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsert' is not null or undefined
            assertParamExists('productControllerImportTestXML', 'upsert', upsert)
            const localVarPath = `/product/seed/xml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (upsert !== undefined) {
                localVarQueryParameter['upsert'] = upsert;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseARSSlots: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/ars/slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseAlCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/al/parse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/ars/parse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productControllerRemove', 'id', id)
            const localVarPath = `/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedAL: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/al`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedAddons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/addons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedEWG: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/ewg/parse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedSWG: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/seed/swg/parse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdate: async (id: string, updateProductDto: UpdateProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productControllerUpdate', 'id', id)
            // verify required parameter 'updateProductDto' is not null or undefined
            assertParamExists('productControllerUpdate', 'updateProductDto', updateProductDto)
            const localVarPath = `/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCount(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerCount(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreate(createProductDto: CreateProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerCreate(createProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllAddonProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllAddonProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllAddonProductsForProduct(prod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllAddonProductsForProduct(prod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllMainProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllMainProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllSetProductsBySlot(slot: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllSetProductsBySlot(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slot 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllSetProductsBySlotForProduct(slot: string, prod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllSetProductsBySlotForProduct(slot, prod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllSetProductsBySlots(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllSetProductsBySlots(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prod 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindAllSetProductsBySlotsForProduct(prod: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindAllSetProductsBySlotsForProduct(prod, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetDefaultSocket(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetDefaultSocket(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetDefaultSocketForProduct(prod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetDefaultSocketForProduct(prod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetLabelProduct(prod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetLabelProduct(prod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerImportAlAddons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerImportAlAddons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerImportJsonProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerImportJsonProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerImportSwgAddons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerImportSwgAddons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} upsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerImportTestXML(upsert: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerImportTestXML(upsert, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerParseARSSlots(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerParseARSSlots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerParseAlCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerParseAlCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerParseCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerParseCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSeedAL(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerSeedAL(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSeedAddons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerSeedAddons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSeedEWG(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerSeedEWG(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSeedSWG(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerSeedSWG(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerUpdate(id: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerUpdate(id, updateProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerCount(type: string, options?: any): AxiosPromise<number> {
            return localVarFp.productControllerCount(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerCreate(createProductDto: CreateProductDto, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerCreate(createProductDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllAddonProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllAddonProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllAddonProductsForProduct(prod: string, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllAddonProductsForProduct(prod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllMainProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllMainProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlot(slot: string, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllSetProductsBySlot(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slot 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlotForProduct(slot: string, prod: string, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllSetProductsBySlotForProduct(slot, prod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlots(requestBody: Array<string>, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllSetProductsBySlots(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prod 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindAllSetProductsBySlotsForProduct(prod: string, requestBody: Array<string>, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerFindAllSetProductsBySlotsForProduct(prod, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerFindOne(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetDefaultSocket(options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerGetDefaultSocket(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetDefaultSocketForProduct(prod: string, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerGetDefaultSocketForProduct(prod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetLabelProduct(prod: string, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerGetLabelProduct(prod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportAlAddons(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerImportAlAddons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportJsonProducts(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerImportJsonProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportSwgAddons(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerImportSwgAddons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} upsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportTestXML(upsert: string, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerImportTestXML(upsert, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseARSSlots(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerParseARSSlots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseAlCSV(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerParseAlCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerParseCSV(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerParseCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedAL(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerSeedAL(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedAddons(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerSeedAddons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedEWG(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerSeedEWG(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerSeedSWG(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerSeedSWG(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdate(id: string, updateProductDto: UpdateProductDto, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerUpdate(id, updateProductDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerCount(type: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerCount(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProductDto} createProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerCreate(createProductDto: CreateProductDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerCreate(createProductDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAll(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllAddonProducts(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllAddonProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllAddonProductsForProduct(prod: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllAddonProductsForProduct(prod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllMainProducts(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllMainProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllSetProductsBySlot(slot: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllSetProductsBySlot(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slot 
     * @param {string} prod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllSetProductsBySlotForProduct(slot: string, prod: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllSetProductsBySlotForProduct(slot, prod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllSetProductsBySlots(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllSetProductsBySlots(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prod 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindAllSetProductsBySlotsForProduct(prod: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindAllSetProductsBySlotsForProduct(prod, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetDefaultSocket(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetDefaultSocket(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetDefaultSocketForProduct(prod: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetDefaultSocketForProduct(prod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetLabelProduct(prod: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetLabelProduct(prod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerImportAlAddons(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerImportAlAddons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerImportJsonProducts(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerImportJsonProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerImportSwgAddons(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerImportSwgAddons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} upsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerImportTestXML(upsert: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerImportTestXML(upsert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerParseARSSlots(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerParseARSSlots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerParseAlCSV(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerParseAlCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerParseCSV(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerParseCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerRemove(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerSeedAL(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerSeedAL(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerSeedAddons(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerSeedAddons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerSeedEWG(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerSeedEWG(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerSeedSWG(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerSeedSWG(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProductDto} updateProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerUpdate(id: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerUpdate(id, updateProductDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionApi - axios parameter creator
 * @export
 */
export const PromotionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePromotionPayload} createPromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreate: async (createPromotionPayload: CreatePromotionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPromotionPayload' is not null or undefined
            assertParamExists('promotionControllerCreate', 'createPromotionPayload', createPromotionPayload)
            const localVarPath = `/api/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPromotionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerDelete', 'id', id)
            const localVarPath = `/api/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAll: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('promotionControllerFindByName', 'name', name)
            const localVarPath = `/api/promotion/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetOrderCountById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerGetOrderCountById', 'id', id)
            const localVarPath = `/api/promotion/{id}/order-count`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionPayload} updatePromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdate: async (id: string, updatePromotionPayload: UpdatePromotionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerUpdate', 'id', id)
            // verify required parameter 'updatePromotionPayload' is not null or undefined
            assertParamExists('promotionControllerUpdate', 'updatePromotionPayload', updatePromotionPayload)
            const localVarPath = `/api/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePromotionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionApi - functional programming interface
 * @export
 */
export const PromotionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePromotionPayload} createPromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerCreate(createPromotionPayload: CreatePromotionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerCreate(createPromotionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerFindAll(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultPromotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerFindAll(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerFindByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promotion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerFindByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerGetOrderCountById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerGetOrderCountById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionPayload} updatePromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdate(id: string, updatePromotionPayload: UpdatePromotionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdate(id, updatePromotionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionApi - factory interface
 * @export
 */
export const PromotionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePromotionPayload} createPromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreate(createPromotionPayload: CreatePromotionPayload, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerCreate(createPromotionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindAll(limit?: string, offset?: string, options?: any): AxiosPromise<FindAndCountResultPromotion> {
            return localVarFp.promotionControllerFindAll(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerFindByName(name: string, options?: any): AxiosPromise<Array<Promotion>> {
            return localVarFp.promotionControllerFindByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetOrderCountById(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.promotionControllerGetOrderCountById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePromotionPayload} updatePromotionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdate(id: string, updatePromotionPayload: UpdatePromotionPayload, options?: any): AxiosPromise<object> {
            return localVarFp.promotionControllerUpdate(id, updatePromotionPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionApi - object-oriented interface
 * @export
 * @class PromotionApi
 * @extends {BaseAPI}
 */
export class PromotionApi extends BaseAPI {
    /**
     * 
     * @param {CreatePromotionPayload} createPromotionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerCreate(createPromotionPayload: CreatePromotionPayload, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerCreate(createPromotionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerDelete(id: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerFindAll(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerFindAll(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerFindByName(name: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerFindByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerGetOrderCountById(id: string, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerGetOrderCountById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePromotionPayload} updatePromotionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionControllerUpdate(id: string, updatePromotionPayload: UpdatePromotionPayload, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).promotionControllerUpdate(id, updatePromotionPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateLineItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateControllerCreateLineItem', 'id', id)
            const localVarPath = `/template/lineitem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateLineItemTemplate: async (id: string, createTemplatePayload: CreateTemplatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateControllerCreateLineItemTemplate', 'id', id)
            // verify required parameter 'createTemplatePayload' is not null or undefined
            assertParamExists('templateControllerCreateLineItemTemplate', 'createTemplatePayload', createTemplatePayload)
            const localVarPath = `/template/lineitem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTemplatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateOrderTemplate: async (id: string, createTemplatePayload: CreateTemplatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateControllerCreateOrderTemplate', 'id', id)
            // verify required parameter 'createTemplatePayload' is not null or undefined
            assertParamExists('templateControllerCreateOrderTemplate', 'createTemplatePayload', createTemplatePayload)
            const localVarPath = `/template/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTemplatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerDeleteTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateControllerDeleteTemplate', 'id', id)
            const localVarPath = `/template/lineitem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerFindAllLineItemTemplates: async (offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('templateControllerFindAllLineItemTemplates', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('templateControllerFindAllLineItemTemplates', 'limit', limit)
            // verify required parameter 'searchTemplatePayload' is not null or undefined
            assertParamExists('templateControllerFindAllLineItemTemplates', 'searchTemplatePayload', searchTemplatePayload)
            const localVarPath = `/template/lineitem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTemplatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerFindAllOrderTemplates: async (offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('templateControllerFindAllOrderTemplates', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('templateControllerFindAllOrderTemplates', 'limit', limit)
            // verify required parameter 'searchTemplatePayload' is not null or undefined
            assertParamExists('templateControllerFindAllOrderTemplates', 'searchTemplatePayload', searchTemplatePayload)
            const localVarPath = `/template/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTemplatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerCreateLineItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerCreateLineItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerCreateLineItemTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerCreateLineItemTemplate(id, createTemplatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerCreateOrderTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerCreateOrderTemplate(id, createTemplatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerDeleteTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Template>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerDeleteTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerFindAllLineItemTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerFindAllLineItemTemplates(offset, limit, searchTemplatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateControllerFindAllOrderTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAndCountResultTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateControllerFindAllOrderTemplates(offset, limit, searchTemplatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateLineItem(id: string, options?: any): AxiosPromise<Template> {
            return localVarFp.templateControllerCreateLineItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateLineItemTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: any): AxiosPromise<Template> {
            return localVarFp.templateControllerCreateLineItemTemplate(id, createTemplatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTemplatePayload} createTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerCreateOrderTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: any): AxiosPromise<Template> {
            return localVarFp.templateControllerCreateOrderTemplate(id, createTemplatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerDeleteTemplate(id: string, options?: any): AxiosPromise<Array<Template>> {
            return localVarFp.templateControllerDeleteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerFindAllLineItemTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: any): AxiosPromise<FindAndCountResultTemplate> {
            return localVarFp.templateControllerFindAllLineItemTemplates(offset, limit, searchTemplatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offset 
         * @param {string} limit 
         * @param {SearchTemplatePayload} searchTemplatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateControllerFindAllOrderTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: any): AxiosPromise<FindAndCountResultTemplate> {
            return localVarFp.templateControllerFindAllOrderTemplates(offset, limit, searchTemplatePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerCreateLineItem(id: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerCreateLineItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateTemplatePayload} createTemplatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerCreateLineItemTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerCreateLineItemTemplate(id, createTemplatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateTemplatePayload} createTemplatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerCreateOrderTemplate(id: string, createTemplatePayload: CreateTemplatePayload, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerCreateOrderTemplate(id, createTemplatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerDeleteTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerDeleteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offset 
     * @param {string} limit 
     * @param {SearchTemplatePayload} searchTemplatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerFindAllLineItemTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerFindAllLineItemTemplates(offset, limit, searchTemplatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offset 
     * @param {string} limit 
     * @param {SearchTemplatePayload} searchTemplatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateControllerFindAllOrderTemplates(offset: string, limit: string, searchTemplatePayload: SearchTemplatePayload, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateControllerFindAllOrderTemplates(offset, limit, searchTemplatePayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAdminDeleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerAdminDeleteUser', 'id', id)
            const localVarPath = `/user/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllUsersDTO} findAllUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAllUsers: async (limit: string, offset: string, findAllUsersDTO: FindAllUsersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userControllerFindAllUsers', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userControllerFindAllUsers', 'offset', offset)
            // verify required parameter 'findAllUsersDTO' is not null or undefined
            assertParamExists('userControllerFindAllUsers', 'findAllUsersDTO', findAllUsersDTO)
            const localVarPath = `/user/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAllUsersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserAdmin: async (id: string, adminUpdateUserDto: AdminUpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUpdateUserAdmin', 'id', id)
            // verify required parameter 'adminUpdateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateUserAdmin', 'adminUpdateUserDto', adminUpdateUserDto)
            const localVarPath = `/user/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAdminDeleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerAdminDeleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllUsersDTO} findAllUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAllUsers(limit: string, offset: string, findAllUsersDTO: FindAllUsersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllUsersResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindAllUsers(limit, offset, findAllUsersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUserAdmin(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserAdmin(id, adminUpdateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAdminDeleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerAdminDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} limit 
         * @param {string} offset 
         * @param {FindAllUsersDTO} findAllUsersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAllUsers(limit: string, offset: string, findAllUsersDTO: FindAllUsersDTO, options?: any): AxiosPromise<FindAllUsersResultDTO> {
            return localVarFp.userControllerFindAllUsers(limit, offset, findAllUsersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdateUser(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminUpdateUserDto} adminUpdateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserAdmin(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdateUserAdmin(id, adminUpdateUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerAdminDeleteUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerAdminDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} limit 
     * @param {string} offset 
     * @param {FindAllUsersDTO} findAllUsersDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFindAllUsers(limit: string, offset: string, findAllUsersDTO: FindAllUsersDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerFindAllUsers(limit, offset, findAllUsersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateUser(updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AdminUpdateUserDto} adminUpdateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateUserAdmin(id: string, adminUpdateUserDto: AdminUpdateUserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateUserAdmin(id, adminUpdateUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * XmlApi - axios parameter creator
 * @export
 */
export const XmlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xmlControllerGenerateXML: async (order: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('xmlControllerGenerateXML', 'order', order)
            const localVarPath = `/xml/gen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XmlApi - functional programming interface
 * @export
 */
export const XmlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XmlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xmlControllerGenerateXML(order: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xmlControllerGenerateXML(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * XmlApi - factory interface
 * @export
 */
export const XmlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XmlApiFp(configuration)
    return {
        /**
         * 
         * @param {string} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xmlControllerGenerateXML(order: string, options?: any): AxiosPromise<void> {
            return localVarFp.xmlControllerGenerateXML(order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XmlApi - object-oriented interface
 * @export
 * @class XmlApi
 * @extends {BaseAPI}
 */
export class XmlApi extends BaseAPI {
    /**
     * 
     * @param {string} order 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlApi
     */
    public xmlControllerGenerateXML(order: string, options?: AxiosRequestConfig) {
        return XmlApiFp(this.configuration).xmlControllerGenerateXML(order, options).then((request) => request(this.axios, this.basePath));
    }
}


