import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EwgComponent } from './ewg.component';
import { LanguageModule } from '../../../language.module';
import { AtomsModule } from '../../../atoms/atoms.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/pipes.module';
import { BaseComponent } from './slots/base/base.component';
import { EwgAggregationComponent } from './aggregation/saggregation.component';
import { EWGLabelComponent } from './slots/label/label.component';
import { EwgAccesoriesComponent } from './slots/accesories/accesories.component';

@NgModule({
  declarations: [EwgComponent, BaseComponent, EwgAggregationComponent, EWGLabelComponent, EwgAccesoriesComponent],
  imports: [CommonModule, LanguageModule, AtomsModule, MatTooltipModule, PipesModule],
  exports: [EwgComponent, EwgAggregationComponent]
})
export class EwgModule {}
