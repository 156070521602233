import { APP_INITIALIZER, ErrorHandler, NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './routes/auth/login/login.component';
import { RegisterComponent } from './routes/auth/register/register.component';
import { ForgotComponent } from './routes/auth/forgot/forgot.component';
import { WrapperComponent } from './routes/wrapper/wrapper.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DashboardComponent } from './routes/wrapper/dashboard/dashboard.component';
import { ProductBoxComponent } from './components/product-box/product-box.component';
import { TableComponent } from './components/table/table.component';
import { TableHeaderComponent } from './components/table/table-header/table-header.component';
import { TableRowComponent } from './components/table/table-row/table-row.component';
import { CellRendererComponent } from './components/table/cell-renderer/cell-renderer.component';
import { BadgeRendererComponent } from './components/table/cell-renderer/badge-renderer/badge-renderer.component';
import { TablePaginationComponent } from './components/table/table-pagination/table-pagination.component';
import { UserRendererComponent } from './components/table/cell-renderer/user-renderer/user-renderer.component';
import { UsernameRendererComponent } from './components/table/cell-renderer/username-renderer/username-renderer.component';
import { ActionRendererComponent } from './components/table/cell-renderer/action-renderer/action-renderer.component';
import { BranchComponent } from './routes/auth/branch/branch.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ProfileComponent } from './routes/wrapper/profile/profile.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './guards/auth-guard.service';
import { FooterComponent } from './components/footer/footer.component';
import { CompanyComponent } from './components/company/company.component';
import { DateRendererComponent } from './components/table/cell-renderer/date-renderer/date-renderer.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { ConfiguratorComponent } from './routes/wrapper/configurator/configurator.component';
import { OrderBarComponent } from './components/order-bar/order-bar.component';
import { OrderCalculationComponent } from './components/order-calculation/order-calculation.component';
import { OrderFieldsComponent } from './components/order-fields/order-fields.component';
import { OrderFieldComponent } from './components/order-field/ars/order-field.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VarDirective } from './directives/ng-var.directive';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ShortenPipe } from './shorten.pipe';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResetComponent } from './routes/auth/reset/reset.component';
import { OverviewComponent } from './routes/wrapper/overview/overview.component';
import { OverviewOrderComponent } from './components/overview-order/overview-order.component';
import { OverviewAddressComponent } from './components/overview-address/overview-address.component';
import { SortPipe } from './pipes/sort.pipe';
import { AtomsModule } from './atoms/atoms.module';
import { DiscountComponent } from './components/discount/discount.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModule } from './language.module';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { MyMatPaginatorIntl } from './components/table/paginator.intl';
import { IframeComponent } from './components/iframe/iframe.component';
import { RoundPipe } from './pipes/round.pipe';
import { AvkModule } from './components/order-field/avk/avk.module';
import { AlModule } from './components/order-field/al/al.module';
import { PipesModule } from './pipes/pipes.module';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieService } from './services/cookie.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import Hash from '../../commit_hash.json';
import packageJson from '../../package.json';
import { CompanyHasDefault } from './components/company/company-has-default.pipe';
import { CompanyGetDefault } from './components/company/company-get-default.pipe';
import { SwgModule } from './components/order-field/swg/swg.module';
import { PromotionComponent } from './components/promotion/promotion.component';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { EwgModule } from './components/order-field/ewg/ewg.module';

console.log(`ass@${packageJson.version}+${Hash.hash}`);

Sentry.init({
  dsn: 'https://b04233dbd5cf950923e4ea9371987a46@o99865.ingest.sentry.io/4505940812496896',
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ['localhost', /^https:\/\/api\.ass\.hypetrain\.cloud\/api/],
  environment: window.location.hostname.includes('localhost')
    ? 'development'
    : window.location.hostname.includes('stage')
    ? 'stage'
    : 'production',
  release: `ass@${packageJson.version}+${Hash.hash}`,
  // Performance Monitoring
  tracesSampleRate: window.location.hostname.includes('localhost') ? 1.0 : 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function tokenGetter() {
  return localStorage.getItem('ass_token');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#fff'
    },
    button: {
      background: '#dd002e'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a> und
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> gelesen und verstanden haben. 
    </span>
    `
  },
  content: {
    message: 'Durch die Nutzung unserer Website erkennen Sie an, dass Sie unsere ',

    cookiePolicyLink: 'AGB',
    cookiePolicyHref: 'https://www.ass-ag.ch/wp-content/uploads/2021/08/AVB-ASS-Alu-Schaltschra%CC%88nke-AG.pdf',

    privacyPolicyLink: 'Datenschutzbestimmungen',
    privacyPolicyHref: 'https://www.ass-ag.ch/datenschutzerklaerung/'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    WrapperComponent,
    NavbarComponent,
    DashboardComponent,
    ProductBoxComponent,
    TableComponent,
    TableHeaderComponent,
    TableRowComponent,
    CellRendererComponent,
    BadgeRendererComponent,
    TablePaginationComponent,
    UserRendererComponent,
    UsernameRendererComponent,
    ActionRendererComponent,
    BranchComponent,
    PreviewComponent,
    ProfileComponent,
    FooterComponent,
    CompanyComponent,
    DateRendererComponent,
    AddressModalComponent,
    ConfiguratorComponent,
    OrderBarComponent,
    OrderCalculationComponent,
    OrderFieldsComponent,
    OrderFieldComponent,
    OrdersTableComponent,
    VarDirective,
    ShortenPipe,
    ResetComponent,
    OverviewComponent,
    OverviewOrderComponent,
    OverviewAddressComponent,
    PromotionComponent,
    SortPipe,
    DiscountComponent,
    UserModalComponent,
    IframeComponent,
    RoundPipe,
    CompanyHasDefault,
    CompanyGetDefault
  ],
  imports: [
    MatTableModule,
    JwtModule.forRoot({ config: { tokenGetter } }),
    MatSortModule,
    MatTooltipModule,
    MatPaginatorModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageModule,
    BrowserAnimationsModule,
    AtomsModule,
    AvkModule,
    AlModule,
    SwgModule,
    EwgModule,
    MtxTooltipModule,
    PipesModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    AuthGuard,
    CookieService,
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => new MyMatPaginatorIntl(translateService),
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(private translateSvc: TranslateService) {}
}
