import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreateProductLineItemDto, Order, ProductLineItem, UpdateProductLineItemDto } from '../../../../api-client';
import { OrderService } from '../../../../services/order.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-ewg-aggregation',
  templateUrl: './aggregation.component.html',
  styleUrls: ['./aggregation.component.scss']
})
export class EwgAggregationComponent implements OnInit {
  @Input()
  order!: Order;

  lineItemAggregations$: Observable<{ amount: number; productNumber: string; item: ProductLineItem }[]> =
    this.orderService.order$.pipe(
      map((order) => {
        if (!order) return [];
        const lineItemAggregations: { amount: number; productNumber: string; item: ProductLineItem }[] = [];
        for (const item of order.lineItems) {
          if (item.isAddon) {
            if (lineItemAggregations.some((l) => l.productNumber === item.productNumber)) {
              lineItemAggregations.find((l) => l.productNumber === item.productNumber)!.amount += item.amount;
            } else {
              lineItemAggregations.push({
                amount: item.amount,
                productNumber: item.productNumber,
                item: item
              });
            }
          }
        }

        return lineItemAggregations;
      })
    );

  constructor(public readonly orderService: OrderService, public readonly translate: TranslateService) {}

  ngOnInit(): void {}
}
