<div class="container px-4 py-12 lg:py-20 mx-auto space-y-16 lg:px-8">
  <div class="flex flex-col">
    <span class="text-3xl font-bold uppercase"
      >{{ 'Dashboard.Header' | translate }} <span class="text-monza-500">{{ authService.currentUser?.firstName }}</span
      >,</span
    >
    <span class="text-gray-500">{{ 'Dashboard.SubHeader' | translate }}</span>
  </div>
  <div class="flex w-full" *ngIf="this.productService.sortedMainProducts$ | async as mainProdcuts; else loadingProducts">
    <ng-container *ngVar="this.productService.mainProductOffset$ | async as offset">
      <ng-container *ngVar="this.productService.mainProducts$ | async as unfilteredProducts">
        <div class="w-10 h-auto mr-4 mt-8 p-2 bg-monza-500 flex cursor-pointer" *ngIf="offset > 0" (click)="this.productService.mainProductOffset$.next(offset-this.productService.MAIN_PRODUCT_LIMIT)">
          <app-icon icon="arrow-right-alt" class="text-white rotate-180"></app-icon>
        </div>
        <div class="grid gap-4 gap-y-8 w-full grid-cols-5 items-end">
          <app-product-box
            class="col-span-3 sm:col-span-2 lg:col-span-1"
            *ngFor="let mainProduct of mainProdcuts"
            [id]="mainProduct.id"
            [name]="mainProduct.name"
            [short]="mainProduct.type"
            [image]="'assets/preview-'+mainProduct.type+'.png'"
            (onClick)="createNewOrder(mainProduct.id)"
          ></app-product-box>
        </div>
        <div class="w-10 h-auto ml-4 mt-8 p-2 bg-monza-500 flex cursor-pointer" *ngIf="unfilteredProducts.length > (offset + this.productService.MAIN_PRODUCT_LIMIT)" (click)="this.productService.mainProductOffset$.next(offset+this.productService.MAIN_PRODUCT_LIMIT)">
          <app-icon icon="arrow-right-alt" class="text-white"></app-icon>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #loadingProducts>
    <div class="grid grid-cols-3 gap-4 h-80">
      <div class="col-span-3 md:col-span-1 mt-8 bg-white opacity-50"></div>
      <div class="col-span-3 md:col-span-1 mt-8 bg-white opacity-50"></div>
      <div class="col-span-3 md:col-span-1 mt-8 bg-white opacity-50"></div>
    </div>
  </ng-template>
  <!-- drafts -->
  <app-draft-select></app-draft-select>
  <app-orders-table></app-orders-table>
</div>
